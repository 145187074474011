import React, { Component } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import PropTypes from "prop-types";
import { FormFeedback } from "reactstrap";

class ReCaptcha extends Component {
  constructor() {
    super();

    this.state = { recaptchaRef: React.createRef() };
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.submitting !== prevProps.submitting &&
      !this.props.submitting
    ) {
      const { recaptchaRef } = this.state;
      recaptchaRef.current.reset();
    }
  }

  render() {
    const {
      input,
      meta: { touched, error },
    } = this.props;
    const { recaptchaRef } = this.state;

    return (
      <div className="recaptcha-wrapper">
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.EWZ_RECAPTCHA_SITE_KEY}
          onChange={input.onChange}
        />
        {touched && error && (
          <FormFeedback className="force-display-invalid-feedback">
            {error}
          </FormFeedback>
        )}
      </div>
    );
  }
}

ReCaptcha.propTypes = {
  input: PropTypes.object.isRequired,
  submitting: PropTypes.bool.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default ReCaptcha;
