import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Collapse,
  Button,
  CardBody,
  CardHeader,
  Card,
} from "reactstrap";
import moment from "moment-timezone";
import { Link, useHistory } from "react-router-dom";
import { SearchActions, SearchSelectors } from "../../shared/data/search";
import { BookingActions } from "../../shared/data/booking";
import MapWithOnePin from "../components/MapWithOnePin";
import Loader from "../components/Loader";
import { getUrlPrefix } from "../../shared/data/common/CommonSelectors";
import MySportSessionIcon from "../../shared/components/MySportSessionIcon";
import { CommonSelectors } from "../../shared/data/common";
import FavoriteButtonContainer from "../../shared/containers/FavoriteButtonContainer";
import { Helmet } from "react-helmet";
import AddToCalendarModal from "../components/AddToCalendarModal";
import ToBookInformationModalContainer from "./ToBookInformationModalContainer";
import axios from "axios";
import { useSearchContext } from "../data-loader/SearchContext";
import WhatsAppGroupsBlock from "../components/WhatsAppGroupsBlock";

const WhiteLabelMasker = ({ children }) => {
  const { searchParams } = useSearchContext();

  if (searchParams?.isWhiteLabel) {
    return <></>;
  }

  return children;
};

const BackButton = ({ session }) => {
  const { urlPrefix, searchParams, setSearchParams } = useSearchContext();
  let history = useHistory();

  if (history.length > 1) {
    return (
      <a
        className="return-btn"
        href="#"
        onClick={() => {
          history.goBack();
        }}
      >
        <i className="fas fa-angle-double-left" />
        Retour
      </a>
    );
  }

  return (
    <Link
      to={urlPrefix || "/"}
      className="return-btn"
      onClick={() => {
        if (
          searchParams?.address ||
          searchParams?.organizationId ||
          searchParams?.partnerId
        ) {
          return;
        }

        setSearchParams({
          ...searchParams,
          organizationId: session.organizationId,
        });
      }}
    >
      <i className="fas fa-angle-double-left" />
      Retour
    </Link>
  );
};

class SessionContainer extends Component {
  constructor(props) {
    super(props);

    // 992 = tablet / laptop breakpoint
    const blockCloseIds =
      window.innerWidth >= 992
        ? []
        : [
            "description",
            "optionsInformation",
            "paymentLink",
            "requiredEquipments",
            "prerequisite",
            "paymentMethods",
            "extraInformation",
            "mssReview",
            "priceDetails",
            "athleteList",
            "planning",
            "whatsAppGroups",
          ];

    this.state = {
      blockCloseIds,
      addToCalendarModalIsOpen: false,
    };
  }

  componentDidMount() {
    const { match, fetchCurrentSession } = this.props;
    setTimeout(() => {
      fetchCurrentSession(match.params.id);
    }, 2000);
  }

  toggleBlock(blockId) {
    const { blockCloseIds } = this.state;

    if (blockCloseIds.includes(blockId)) {
      const index = blockCloseIds.indexOf(blockId);
      blockCloseIds.splice(index, 1);
    } else {
      blockCloseIds.push(blockId);
    }

    this.setState({ blockCloseIds: [...blockCloseIds] });
  }

  toggleAddToCalendarModal(value) {
    this.setState({ addToCalendarModalIsOpen: value });
  }

  renderBookingButton() {
    const {
      currentSession,
      openBookingModal,
      bookings,
      openWaitingListModal,
      openToBookInformationModal,
    } = this.props;

    const booking = bookings.find((e) => e.sessionId === currentSession.id);
    if (booking) {
      return (
        <div className="booking-cta">
          <Button tag="a" href={`/dashboard/booking/${booking.token}`}>
            Vous avez déjà une réservation
          </Button>
        </div>
      );
    }

    if (
      !currentSession.withoutBooking &&
      currentSession.isBookable &&
      (currentSession.remainingSeats >= currentSession.minUnitsByBooking ||
        currentSession.remainingSeats === null)
    ) {
      return (
        <div className="booking-cta">
          <Button onClick={() => openBookingModal(currentSession)}>
            Je réserve
          </Button>
        </div>
      );
    }

    if (
      !currentSession.withoutBooking &&
      currentSession.remainingSeats < currentSession.minUnitsByBooking &&
      currentSession.hasWaitingList &&
      currentSession.isInWaitingList
    ) {
      return (
        <div className="booking-cta">
          <Button href={`/waitingList/${currentSession.id}/remove`}>
            Quitter la liste d'attente
          </Button>
        </div>
      );
    }

    if (
      !currentSession.isBookable &&
      (currentSession.remainingSeats >= currentSession.minUnitsByBooking ||
        currentSession.remainingSeats === null)
    ) {
      return (
        <div className="booking-cta">
          <Button
            onClick={() => {
              axios.post(
                `/search/api/session/${currentSession.id}/interaction/withoutBookingSessionClick`
              );
              return openToBookInformationModal(currentSession);
            }}
          >
            {!currentSession.bookingLink && !currentSession.bookingPhoneNumber
              ? "J'y vais"
              : "Je veux réserver"}
          </Button>
        </div>
      );
    }

    if (
      !currentSession.withoutBooking &&
      currentSession.isBookable &&
      currentSession.remainingSeats < currentSession.minUnitsByBooking &&
      currentSession.hasWaitingList &&
      !currentSession.isInWaitingList
    ) {
      return (
        <div className="booking-cta">
          <Button onClick={() => openWaitingListModal(currentSession)}>
            Je m'inscris sur la liste d'attente
          </Button>
        </div>
      );
    }

    if (currentSession.withoutBooking) {
      return (
        <div className="booking-cta">
          <Button
            onClick={() => {
              axios.post(
                `/search/api/session/${currentSession.id}/interaction/withoutBookingSessionClick`
              );
              return openToBookInformationModal(currentSession);
            }}
          >
            {!currentSession.bookingLink && !currentSession.bookingPhoneNumber
              ? "J'y vais"
              : "Je veux réserver"}
          </Button>
        </div>
      );
    }

    return null;
  }

  render() {
    const {
      currentSession,
      currentSessionIsLoading,
      urlPrefix,
      platform,
      isConnected,
    } = this.props;
    const { blockCloseIds, addToCalendarModalIsOpen } = this.state;

    return (
      <div
        className="session-page container-fluid"
        style={{ whiteSpace: "pre-wrap" }}
      >
        {currentSessionIsLoading && <Loader />}
        {!currentSessionIsLoading && currentSession && (
          <Row>
            <Helmet>
              <title>{`${currentSession.sport} / ${currentSession.title} par ${
                currentSession.laps
              } le ${moment(currentSession.date).format(
                "dddd Do MMMM YYYY"
              )} à ${moment(currentSession.date).format("k:mm")}`}</title>
              <meta
                property="og:title"
                content={`${currentSession.sport} / ${
                  currentSession.title
                } par ${currentSession.laps} le ${moment(
                  currentSession.date
                ).format("dddd Do MMMM YYYY")} à ${moment(
                  currentSession.date
                ).format("k:mm")}`}
              />
              <meta
                property="og:image"
                itemProp="image"
                content={currentSession.picture.large}
              />
              <meta
                property="og:image:secure_url"
                itemProp="image"
                content={currentSession.picture.large}
              />
              <meta property="og:image:width" content="600" />
              <meta property="og:image:height" content="400" />
              <meta
                property="og:url"
                content={`${window.location.protocol}://${window.location.hostname}/${urlPrefix}/session/${currentSession.id}`}
              />
              <link
                rel="canonical"
                href={`${window.location.protocol}://${window.location.hostname}/${urlPrefix}/session/${currentSession.id}`}
              />
            </Helmet>
            <Col xs={12} className="head">
              <BackButton session={currentSession} />
              <div className="head-content">
                <h1>{currentSession.title}</h1>
                <h2>
                  {currentSession.sport} /
                  {!!currentSession.organizationLink && (
                    <a href={currentSession.organizationLink}>
                      {" "}
                      {currentSession.laps}
                    </a>
                  )}
                  {!currentSession.organizationLink && (
                    <span> {currentSession.laps}</span>
                  )}
                  {currentSession.courtName && (
                    <span> / {currentSession.courtName}</span>
                  )}
                </h2>
              </div>
              {this.renderBookingButton()}
            </Col>

            <Col xs={12} md={8}>
              <div className="price-block">
                <Card>
                  <CardBody>
                    <div className="d-block d-md-flex">
                      {/*<img src={currentSession.picture.large} alt={currentSession.place.name} height="170" className="img-session" />*/}
                      <div
                        className="img-container"
                        style={{
                          backgroundImage: `url(${currentSession.picture.large})`,
                        }}
                      />
                      <div className="content-container">
                        <FavoriteButtonContainer
                          entityId={currentSession.id}
                          className="session"
                        />
                        <h3>
                          {currentSession.isCourse
                            ? "Résumé du stage"
                            : "Résumé de la séance"}{" "}
                          {!!currentSession.isOnline && "en ligne"}
                        </h3>
                        <p>
                          <MySportSessionIcon height={25} />
                          {currentSession.sport} / {currentSession.title}{" "}
                          {currentSession.courtName && (
                            <span> / {currentSession.courtName}</span>
                          )}
                        </p>
                        <p>
                          <i className="fas fa-map-marker-alt" />
                          {currentSession.isOnline && (
                            <React.Fragment>
                              Filmé par :{" "}
                              <a href={currentSession.organizationLink}>
                                {currentSession.laps}
                              </a>
                            </React.Fragment>
                          )}
                          {!currentSession.isOnline && (
                            <React.Fragment>
                              <a href={currentSession.organizationLink}>
                                {currentSession.laps}
                              </a>{" "}
                              / {currentSession.place.name}
                            </React.Fragment>
                          )}
                        </p>
                        <p>
                          <i className="fas fa-calendar-alt" />
                          {currentSession.isCourse
                            ? `du ${moment(currentSession.date).format(
                                "ddd Do MMMM à HH:mm"
                              )} au ${moment(currentSession.endDate).format(
                                "ddd Do MMMM à HH:mm"
                              )}`
                            : moment(currentSession.date).format(
                                "dddd Do MMMM YYYY"
                              )}
                        </p>
                        {!currentSession.isCourse && (
                          <p>
                            <i className="fas fa-clock" />
                            <span
                              className={
                                currentSession.freeAccess ? "" : "m-r-10"
                              }
                            >
                              de {moment(currentSession.date).format("k:mm")} à{" "}
                              {moment(currentSession.endDate).format("k:mm")}{" "}
                              {currentSession.freeAccess && (
                                <span>(en accès libre)</span>
                              )}
                            </span>
                            {!currentSession.freeAccess && (
                              <span className="m-l-20">
                                <i className="fas fa-stopwatch" />{" "}
                                {currentSession.duration}
                              </span>
                            )}
                            {/*<Button onClick={() => this.toggleAddToCalendarModal(true)} outline size='sm' color='primary d-none d-sm-block' className="m-l-20">Ajouter au calendrier</Button>*/}
                          </p>
                        )}
                        {/*<div>*/}
                        {/*  <Button onClick={() => this.toggleAddToCalendarModal(true)} outline block size='sm' color='primary d-block d-sm-none' className="m-t-10">Ajouter au calendrier</Button>*/}
                        {/*</div>*/}
                        <div className="available-seats m-t-20">
                          {/*{currentSession.withoutBooking && <span><i className="fas fa-exclamation-circle text-success" />Sans réservation</span>}*/}
                          {!currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats !== null &&
                            currentSession.remainingSeats <
                              currentSession.minUnitsByBooking && (
                              <span>
                                <i className="fas fa-exclamation-circle text-danger" />
                                Complet
                              </span>
                            )}
                          {currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats !== null &&
                            currentSession.remainingSeats <
                              currentSession.minUnitsByBooking &&
                            !currentSession.hasWaitingList && (
                              <span>
                                <i className="fas fa-exclamation-circle text-danger" />
                                Complet
                              </span>
                            )}
                          {currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats !== null &&
                            currentSession.remainingSeats <
                              currentSession.minUnitsByBooking &&
                            currentSession.hasWaitingList &&
                            !currentSession.isInWaitingList && (
                              <span>
                                <i className="fas fa-exclamation-circle text-warning" />
                                Complet mais liste d'attente disponible
                              </span>
                            )}
                          {currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats !== null &&
                            currentSession.remainingSeats <
                              currentSession.minUnitsByBooking &&
                            currentSession.hasWaitingList &&
                            currentSession.isInWaitingList && (
                              <span>
                                <i className="fas fa-exclamation-circle text-warning" />
                                Complet & inscrit sur la liste d'attente
                              </span>
                            )}
                          {currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats !== null &&
                            currentSession.remainingSeats >=
                              currentSession.minUnitsByBooking &&
                            currentSession.remainingSeats === 1 && (
                              <span>
                                <i className="fas fa-exclamation-circle text-warning" />
                                1 {currentSession.unit.name} disponible
                              </span>
                            )}
                          {currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats !== null &&
                            currentSession.remainingSeats >=
                              currentSession.minUnitsByBooking &&
                            currentSession.remainingSeats > 1 && (
                              <span>
                                <i className="fas fa-exclamation-circle text-success" />
                                {currentSession.remainingSeats}{" "}
                                {currentSession.unit.pluralName} disponibles
                              </span>
                            )}
                          {currentSession.isBookable &&
                            !currentSession.withoutBooking &&
                            currentSession.remainingSeats === null && (
                              <span>
                                <i className="fas fa-exclamation-circle text-success" />
                                ∞ {currentSession.unit.pluralName} disponibles
                              </span>
                            )}
                        </div>
                        <div className="price">
                          {currentSession.price == 0 && (
                            <>
                              {currentSession.subscriberMode === 2 ? (
                                <>ABONNÉS</>
                              ) : (
                                <>OFFERT</>
                              )}
                            </>
                          )}
                          {currentSession.price > 0 && (
                            <>
                              {currentSession.subscriberMode === 1 && (
                                <>{currentSession.price} €</>
                              )}
                              {currentSession.subscriberMode === 2 && (
                                <>ABONNÉS</>
                              )}
                              {currentSession.subscriberMode === 3 && (
                                <>{currentSession.price} € (ou ABONNÉS)</>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </div>

              <div className="accordion-details">
                <Card>
                  <CardHeader onClick={() => this.toggleBlock("description")}>
                    Description{" "}
                    {!!currentSession.isOnline && "de la séance en ligne"}
                    <i
                      className={`fas fa-${
                        blockCloseIds.includes("description") ? "plus" : "minus"
                      }`}
                    />
                  </CardHeader>
                  <Collapse
                    id="description"
                    isOpen={!blockCloseIds.includes("description")}
                  >
                    <CardBody>{currentSession.description}</CardBody>
                  </Collapse>
                </Card>
                {currentSession.isCourse && (
                  <Card>
                    <CardHeader onClick={() => this.toggleBlock("planning")}>
                      Planning
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("planning") ? "plus" : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="planning"
                      isOpen={!blockCloseIds.includes("planning")}
                    >
                      <CardBody>
                        <ul>
                          {currentSession.subSessions.map((subSession) => (
                            <li key={subSession.id}>
                              {moment(subSession.startDate).format(
                                "dddd Do/MM/YYYY [de] HH[h]mm"
                              )}{" "}
                              à {moment(subSession.endDate).format("HH[h]mm")}
                              {subSession.title && <>: {subSession.title}</>}
                            </li>
                          ))}
                        </ul>
                      </CardBody>
                    </Collapse>
                  </Card>
                )}

                {currentSession.paymentLinkEnabled && (
                  <Card>
                    <CardHeader onClick={() => this.toggleBlock("paymentLink")}>
                      Conditions de paiement et d'annulation
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("paymentLink")
                            ? "plus"
                            : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="paymentLink"
                      isOpen={!blockCloseIds.includes("paymentLink")}
                    >
                      <CardBody>
                        <div style={{ whiteSpace: "pre-line" }}>
                          {currentSession.paymentLinkExplanation}
                        </div>
                      </CardBody>
                    </Collapse>
                  </Card>
                )}
                <WhiteLabelMasker>
                  {currentSession.mssReview && (
                    <Card>
                      <CardHeader onClick={() => this.toggleBlock("mssReview")}>
                        L'avis de My Sport Session
                        <i
                          className={`fas fa-${
                            blockCloseIds.includes("mssReview")
                              ? "plus"
                              : "minus"
                          }`}
                        />
                      </CardHeader>
                      <Collapse
                        id="mssReview"
                        isOpen={!blockCloseIds.includes("mssReview")}
                      >
                        <CardBody>{currentSession.mssReview}</CardBody>
                      </Collapse>
                    </Card>
                  )}
                </WhiteLabelMasker>
                {currentSession.requiredEquipments &&
                  currentSession.requiredEquipments.length > 0 && (
                    <Card>
                      <CardHeader
                        onClick={() => this.toggleBlock("requiredEquipments")}
                      >
                        Matériels requis
                        <i
                          className={`fas fa-${
                            blockCloseIds.includes("requiredEquipments")
                              ? "plus"
                              : "minus"
                          }`}
                        />
                      </CardHeader>
                      <Collapse
                        id="requiredEquipments"
                        isOpen={!blockCloseIds.includes("requiredEquipments")}
                      >
                        <CardBody>{currentSession.requiredEquipments}</CardBody>
                      </Collapse>
                    </Card>
                  )}
                {(currentSession.prerequisite ||
                  (currentSession.requiredAge &&
                    (currentSession.requiredAge.min ||
                      currentSession.requiredAge.max))) && (
                  <Card>
                    <CardHeader
                      onClick={() => this.toggleBlock("prerequisite")}
                    >
                      Pré-requis
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("prerequisite")
                            ? "plus"
                            : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="prerequisite"
                      isOpen={!blockCloseIds.includes("prerequisite")}
                    >
                      <CardBody>
                        {currentSession.requiredAge &&
                          currentSession.requiredAge.min && (
                            <p>{`Age minimum: ${currentSession.requiredAge.min} ans`}</p>
                          )}
                        {currentSession.requiredAge &&
                          currentSession.requiredAge.max && (
                            <p>{`Age maximum: ${currentSession.requiredAge.max} ans`}</p>
                          )}
                        {currentSession.prerequisite}
                      </CardBody>
                    </Collapse>
                  </Card>
                )}
                {currentSession.paymentMethods &&
                  currentSession.paymentMethods.length > 0 && (
                    <Card>
                      <CardHeader
                        onClick={() => this.toggleBlock("paymentMethods")}
                      >
                        Moyens de paiement
                        <i
                          className={`fas fa-${
                            blockCloseIds.includes("paymentMethods")
                              ? "plus"
                              : "minus"
                          }`}
                        />
                      </CardHeader>
                      <Collapse
                        id="paymentMethods"
                        isOpen={!blockCloseIds.includes("paymentMethods")}
                      >
                        <CardBody>
                          {currentSession.paymentMethods.join(" / ")}
                        </CardBody>
                      </Collapse>
                    </Card>
                  )}
                {currentSession.extraInformation && (
                  <Card>
                    <CardHeader
                      onClick={() => this.toggleBlock("extraInformation")}
                    >
                      Informations complémentaires
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("extraInformation")
                            ? "plus"
                            : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="extraInformation"
                      isOpen={!blockCloseIds.includes("extraInformation")}
                    >
                      <CardBody>{currentSession.extraInformation}</CardBody>
                    </Collapse>
                  </Card>
                )}
                {currentSession.optionsInformation && (
                  <Card>
                    <CardHeader
                      onClick={() => this.toggleBlock("optionsInformation")}
                    >
                      Options disponibles
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("optionsInformation")
                            ? "plus"
                            : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="optionsInformation"
                      isOpen={!blockCloseIds.includes("optionsInformation")}
                    >
                      <CardBody>{currentSession.optionsInformation}</CardBody>
                    </Collapse>
                  </Card>
                )}
                {currentSession.priceDetails && (
                  <Card>
                    <CardHeader
                      onClick={() => this.toggleBlock("priceDetails")}
                    >
                      Abonnements, cartes & tarifs
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("priceDetails")
                            ? "plus"
                            : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="priceDetails"
                      isOpen={!blockCloseIds.includes("priceDetails")}
                    >
                      <CardBody>{currentSession.priceDetails}</CardBody>
                    </Collapse>
                  </Card>
                )}
                {currentSession.onlyForRegisteredMembers &&
                  currentSession.registeredMembersInformation && (
                    <Card>
                      <CardHeader
                        onClick={() =>
                          this.toggleBlock("registeredMembersInformation")
                        }
                      >
                        Licence ou permis requis
                        <i
                          className={`fas fa-${
                            blockCloseIds.includes(
                              "registeredMembersInformation"
                            )
                              ? "plus"
                              : "minus"
                          }`}
                        />
                      </CardHeader>
                      <Collapse
                        id="priceDetails"
                        isOpen={
                          !blockCloseIds.includes(
                            "registeredMembersInformation"
                          )
                        }
                      >
                        <CardBody>
                          {currentSession.registeredMembersInformation}
                        </CardBody>
                      </Collapse>
                    </Card>
                  )}
                {currentSession.athleteList.canDisplay && (
                  <Card>
                    <CardHeader onClick={() => this.toggleBlock("athleteList")}>
                      Participants
                      <i
                        className={`fas fa-${
                          blockCloseIds.includes("athleteList")
                            ? "plus"
                            : "minus"
                        }`}
                      />
                    </CardHeader>
                    <Collapse
                      id="athleteList"
                      isOpen={!blockCloseIds.includes("athleteList")}
                    >
                      <CardBody>
                        {!currentSession.athleteList.isDisplay && (
                          <p>
                            Vous devez disposer d'un abonnement valable pour
                            cette séance pour voir les participants.
                          </p>
                        )}
                        {currentSession.athleteList.isDisplay &&
                          currentSession.athleteList.list.length === 0 && (
                            <p>
                              Aucun sportif n'est encore inscrit à cette
                              session.
                            </p>
                          )}
                        {currentSession.athleteList.isDisplay &&
                          currentSession.athleteList.list.length > 0 && (
                            <ul>
                              {currentSession.athleteList.list.map((e) => (
                                <li>{e}</li>
                              ))}
                            </ul>
                          )}
                      </CardBody>
                    </Collapse>
                  </Card>
                )}
                <WhatsAppGroupsBlock
                  isLogged={isConnected}
                  isSubscriber={currentSession.athleteList.canDisplay}
                  organizationId={currentSession.organizationId}
                  sportId={currentSession.sportId}
                  isOpen={!blockCloseIds.includes("whatsAppGroups")}
                  onClickHeader={() => this.toggleBlock("whatsAppGroups")}
                />
              </div>
            </Col>
            <Col xs={12} md={4}>
              <div className="place-details">
                <Card>
                  <CardHeader>
                    {currentSession.isOnline && (
                      <React.Fragment>
                        Filmé par :{" "}
                        <a href={currentSession.organizationLink}>
                          {currentSession.laps}
                        </a>
                      </React.Fragment>
                    )}
                    {!currentSession.isOnline && (
                      <React.Fragment>
                        <i className="fas fa-map-marker-alt m-r-10" />
                        {!!currentSession.organizationLink && (
                          <a href={currentSession.organizationLink}>
                            {currentSession.place.name}
                          </a>
                        )}
                        {!currentSession.organizationLink && (
                          <span>{currentSession.place.name}</span>
                        )}
                      </React.Fragment>
                    )}
                    <FavoriteButtonContainer
                      entityId={currentSession.organizationId}
                      className="organization"
                    />
                  </CardHeader>
                  <CardBody>
                    {currentSession.coordinates && (
                      <MapWithOnePin
                        lat={currentSession.coordinates.lat}
                        lng={currentSession.coordinates.lng}
                        zoom={currentSession.isOnline ? 5 : 15}
                        dragging={window.innerWidth > 768}
                        scrollWheelZoom={window.innerWidth > 768}
                      />
                    )}
                    {!!currentSession.organizationLink && (
                      <p>
                        <i className="fas fa-user-friends" />
                        <a
                          href={currentSession.organizationLink}
                          className="underline"
                        >
                          En savoir plus sur {currentSession.laps}
                        </a>
                      </p>
                    )}
                    {currentSession.place && currentSession.place.address && (
                      <p>
                        <i className="fas fa-map-marker-alt" />
                        Adresse: {currentSession.place.address}{" "}
                        <a
                          href={`https://www.google.com/maps/search/${currentSession.coordinates.lat},${currentSession.coordinates.lng}`}
                          target="_blank"
                          className="underline"
                        >
                          Comment y aller ?
                        </a>
                      </p>
                    )}
                    {!currentSession.isOnline &&
                      currentSession.place &&
                      currentSession.place.availableServices.length > 0 && (
                        <p>
                          <i className="fas fa-concierge-bell" />
                          Services disponibles sur place:{" "}
                          {currentSession.place.availableServices.join(", ")}
                          {currentSession.place.otherServices &&
                            currentSession.place.availableServices.length > 0 &&
                            " / "}
                          {currentSession.place.otherServices}
                        </p>
                      )}
                  </CardBody>
                </Card>
              </div>

              <div className="custom-details d-block">
                {this.renderBookingButton()}
              </div>
            </Col>
            <AddToCalendarModal
              session={currentSession}
              isOpen={addToCalendarModalIsOpen}
              closeCallback={() => this.toggleAddToCalendarModal(false)}
            />
            {(currentSession.withoutBooking || !currentSession.isBookable) && (
              <ToBookInformationModalContainer />
            )}
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  currentSession: SearchSelectors.getCurrentSession(state),
  currentSessionIsLoading: SearchSelectors.currentSessionIsLoading(state),
  bookings: SearchSelectors.getBookings(state),
  platform: CommonSelectors.getPlatform(state),
  urlPrefix: getUrlPrefix(state),
  isConnected: CommonSelectors.getUserIsConnected(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchCurrentSession: (sessionId) =>
    dispatch(SearchActions.fetchCurrentSession(sessionId)),
  openBookingModal: (session) =>
    dispatch(BookingActions.openBookingModal(session)),
  openWaitingListModal: (session) =>
    dispatch(BookingActions.openWaitingListModal(session)),
  openToBookInformationModal: (session) =>
    dispatch(BookingActions.openToBookInformationModal(session)),
});

SessionContainer.propTypes = {
  currentSession: PropTypes.object,
  urlPrefix: PropTypes.string,
  currentSessionIsLoading: PropTypes.bool.isRequired,
  fetchCurrentSession: PropTypes.func.isRequired,
  openBookingModal: PropTypes.func.isRequired,
  openWaitingListModal: PropTypes.func.isRequired,
  openToBookInformationModal: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  platform: PropTypes.string.isRequired,
  bookings: PropTypes.array.isRequired,
  isConnected: PropTypes.bool.isRequired,
};

SessionContainer.defaultProps = {
  currentSession: null,
  urlPrefix: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(SessionContainer);
