import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import {
  getParamAvailableOnly,
  getParamHideForSubscriber,
  showFilterModal,
} from "../../shared/data/search/SearchSelectors";
import { SearchActions } from "../../shared/data/search";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

class FilterModalContainer extends Component {
  render() {
    const {
      showFilterModal,
      paramAvailableOnly,
      toggleFilterModal,
      setParamAvailableOnly,
      paramHideForSubscriber,
      setParamHideForSubscriber,
    } = this.props;

    return (
      <Modal
        className="search-filter-modal"
        isOpen={showFilterModal}
        toggle={() => toggleFilterModal(false)}
      >
        <ModalHeader toggle={() => toggleFilterModal(false)}>
          Filtres
        </ModalHeader>
        <ModalBody>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="available-only"
              checked={paramAvailableOnly}
              onChange={(event) => setParamAvailableOnly(event.target.checked)}
            />
            <label className="form-check-label" htmlFor="available-only">
              Masquer les séances complètes
            </label>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="available-only"
              checked={paramAvailableOnly}
              onChange={(event) => setParamAvailableOnly(event.target.checked)}
            />
            <label className="form-check-label" htmlFor="available-only">
              Masquer les séances en liste d’attente
            </label>
          </div>
          <div className="mb-3 form-check">
            <input
              type="checkbox"
              className="form-check-input"
              id="hide-for-subscriber"
              checked={paramHideForSubscriber}
              onChange={(event) => setParamHideForSubscriber(event.target.checked)}
            />
            <label className="form-check-label" htmlFor="hide-for-subscriber">
              Masquer les offres adhérents/abonnés
            </label>
          </div>
          <Button
            block
            onClick={() => toggleFilterModal(false)}
            color="primary"
          >
            Appliquer les filtres
          </Button>
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  showFilterModal: showFilterModal(state),
  paramAvailableOnly: getParamAvailableOnly(state),
  paramHideForSubscriber: getParamHideForSubscriber(state),
});

const mapDispatchToProps = (dispatch) => ({
  toggleFilterModal: (value) =>
    dispatch(SearchActions.toggleFilterModal(value)),
  setParamAvailableOnly: (value) =>
    dispatch(SearchActions.setSearchParamAvailableOnly(value)),
  setParamHideForSubscriber: (value) =>
    dispatch(SearchActions.setSearchParamHideForSubscriber(value)),
});

FilterModalContainer.propTypes = {
  showFilterModal: PropTypes.bool.isRequired,
  paramAvailableOnly: PropTypes.bool.isRequired,
  paramHideForSubscriber: PropTypes.bool.isRequired,
  toggleFilterModal: PropTypes.func.isRequired,
  setParamAvailableOnly: PropTypes.func.isRequired,
  setParamHideForSubscriber: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FilterModalContainer);
