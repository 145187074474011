import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import RootContainer from "./containers/RootContainer";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";

import "react-widgets/dist/css/react-widgets.css";
import { ReactQueryDevtools } from "react-query/devtools";
import { QueryClient, QueryClientProvider } from "react-query";
import SearchDataLoader from "../_search/data-loader/SearchDataLoader";

Moment.locale("fr");
momentLocalizer();

const queryClient = new QueryClient();

// See documentation for https://github.com/reactjs/react-redux.
// This code here binds your smart component to the redux store.
const AppSearchForm = () => {
  // const store = ReactOnRails.getStore("searchFormStore");

  return (
    // <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <SearchDataLoader editUrlParams={false}>
        <RootContainer />
      </SearchDataLoader>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
    // </Provider>
  );
};

export default AppSearchForm;
