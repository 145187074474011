"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSearchContext = exports.SearchContext = void 0;
const react_1 = require("react");
exports.SearchContext = (0, react_1.createContext)({
    selectedTemplate: null,
    setSelectedTemplate: () => { },
    showMobileMap: false,
    setShowMobileMap: () => { },
    searchParams: null,
    editSearchParam: null,
    setSearchParams: null,
    whiteLabel: null,
    setWhiteLabel: () => { },
    hoveredPlaceIdOnGrid: null,
    setHoveredPlaceIdOnGrid: () => { },
    hoveredPlaceIdOnMap: null,
    setHoveredPlaceIdOnMap: () => { },
    isInited: false,
    urlPrefix: null,
    specificOrganizationId: null,
});
const useSearchContext = () => {
    return (0, react_1.useContext)(exports.SearchContext);
};
exports.useSearchContext = useSearchContext;
