export const AUTOCOMPLETE_FETCH = "AUTOCOMPLETE_FETCH";
export const AUTOCOMPLETE_RECEIVED = "AUTOCOMPLETE_RECEIVED";
export const AUTOCOMPLETE_ERROR = "AUTOCOMPLETE_ERROR";

export const AUTOCOMPLETE_SELECT = "AUTOCOMPLETE_SELECT";
export const AUTOCOMPLETE_SELECTED = "AUTOCOMPLETE_SELECTED";
export const AUTOCOMPLETE_RESET = "AUTOCOMPLETE_RESET";

export const AUTOCOMPLETE_UNSELECT = "AUTOCOMPLETE_UNSELECT";

export const LOCATION_FETCH = "AUTOCOMPLETE_LOCATION_FETCH";
export const LOCATION_RECEIVED = "AUTOCOMPLETE_LOCATION_RECEIVED";
export const LOCATION_ERROR = "AUTOCOMPLETE_LOCATION_ERROR";
export const LOCATION_EDIT = "AUTOCOMPLETE_LOCATION_EDIT";

export const REVERSE_GEOCODE_FETCH = "REVERSE_GEOCODE_FETCH";
export const REVERSE_GEOCODE_RECEIVED = "REVERSE_GEOCODE_RECEIVED";
export const REVERSE_GEOCODE_ERROR = "REVERSE_GEOCODE_ERROR";

export const LAST_SELECTED_PLACES_ADD = "LAST_SELECTED_PLACES_ADD";
export const LAST_SELECTED_PLACES_RETRIEVE = "LAST_SELECTED_PLACES_RETRIEVE";
export const LAST_SELECTED_PLACES_RETRIEVED = "LAST_SELECTED_PLACES_RETRIEVED";
export const LAST_SELECTED_PLACES_AUTOFILL = "LAST_SELECTED_PLACES_AUTOFILL";
