export const getMode = (state) => state.search.mode;
export const getSportsForSelect = (state) => state.search.selects.sports;
export const getOrganizationsForSelect = (state) =>
  state.search.selects.organizations;
export const getActivityTemplatesForSelect = (state) =>
  state.search.selects.activityTemplates;
export const getParams = (state) => state.search.params;
export const getParamSportId = (state) => state.search.params.sportId;
export const getParamOrganizationId = (state) =>
  state.search.params.organizationId;
export const getParamDate = (state) => state.search.params.date;
export const getParamActivityTemplateId = (state) =>
  state.search.params.activityTemplateId;
export const getParamOnlineOnly = (state) => state.search.params.onlineOnly;
export const getParamAvailableOnly = (state) =>
  state.search.params.availableOnly;
export const getParamHideForSubscriber = (state) =>
  state.search.params.hideForSubscriber;
export const getPartnerId = (state) => state.search.params.partnerId;
export const getParamTime = (state) =>
  state.search.params.time ? parseInt(state.search.params.time, 10) : null;
export const getSeances = (state) => state.search.seances;
export const getAlternativeSports = (state) => state.search.alternativeSports;
export const getActivityTemplates = (state) => state.search.activityTemplates;
export const getCourts = (state) => state.search.courts;
export const getPlaces = (state) => state.search.places;
export const seancesAreLoading = (state) => state.search.seancesAreLoading;
export const getCurrentSession = (state) => state.search.currentSession;
export const currentSessionIsLoading = (state) =>
  state.search.currentSessionIsLoading;
export const showMobileMap = (state) => state.search.showMobileMap;
export const showFilterModal = (state) => state.search.showFilterModal;
export const getPlaceHovered = (state) => state.search.placeHovered;
export const getAvailableSports = (state) => state.search.availableSports;
export const getHasMore = (state) => state.search.hasMore;
export const isOnlyForm = (state) => state.search.isOnlyForm;
export const getBookings = (state) => state.search.bookings;
