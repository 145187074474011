export const bookingModalIsOpen = (state) => state.booking.modalIsOpen;
export const waitingListModalIsOpen = (state) =>
  state.booking.waitingListModalIsOpen;
export const toBookInformationModalIsOpen = (state) =>
  state.booking.toBookInformationModalIsOpen;
export const getSession = (state) => state.booking.session;
export const bookingFormIsSuccess = (state) => state.booking.isSuccess;
export const getNumberOfUnits = (state) => state.booking.numberOfUnits;
export const getNumberOfAthletes = (state) => state.booking.numberOfAthletes;
export const getNotifyAthletes = (state) => state.booking.notifyAthletes;
