import { all } from "redux-saga/effects";
import { searchSagas } from "../shared/data/search/SearchSaga";
import { bookingSagas } from "../shared/data/booking/BookingSaga";
import { commonSagas } from "../shared/data/common/CommonSaga";
import { favoriteSagas } from "../shared/data/favorite/FavoriteSaga";
import { autocompleteSagas } from "../shared/data/autocomplete/AutocompleteSaga";

export default function* rootSaga() {
  yield all([
    ...searchSagas,
    ...bookingSagas,
    ...commonSagas,
    ...favoriteSagas,
    ...autocompleteSagas,
  ]);
}
