"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
// @ts-ignore
const reactstrap_1 = require("reactstrap");
// @ts-ignore
const react_leaflet_1 = require("react-leaflet");
const SearchContext_1 = require("../../_search/data-loader/SearchContext");
const Api_1 = require("../../_search/services/Api");
const use_debounce_1 = require("use-debounce");
function PlaceAutocompleteTs({ onChange, noResultBanner = false, }) {
    var _a;
    const { searchParams, setSearchParams } = (0, SearchContext_1.useSearchContext)();
    const [isFocused, setIsFocused] = (0, react_1.useState)(false);
    const [modalIsOpen, setModalIsOpen] = (0, react_1.useState)(false);
    const [inputValue, setInputValue] = (0, react_1.useState)((_a = searchParams === null || searchParams === void 0 ? void 0 : searchParams.address) !== null && _a !== void 0 ? _a : "");
    const [currentPosition, setCurrentPosition] = (0, react_1.useState)();
    const [currentPositionHasError, setCurrentPositionHasError] = (0, react_1.useState)(false);
    const [tmpCoordinates, setTmpCoordinates] = (0, react_1.useState)();
    const onChangeCallback = (0, react_1.useCallback)((value) => {
        setInputValue(value);
    }, []);
    (0, react_1.useEffect)(() => {
        (searchParams === null || searchParams === void 0 ? void 0 : searchParams.address) ? setInputValue(searchParams === null || searchParams === void 0 ? void 0 : searchParams.address) : null;
    }, [searchParams === null || searchParams === void 0 ? void 0 : searchParams.address]);
    (0, react_1.useMemo)(() => {
        var _a;
        if ((searchParams === null || searchParams === void 0 ? void 0 : searchParams.lat) && (searchParams === null || searchParams === void 0 ? void 0 : searchParams.lng)) {
            onChange === null || onChange === void 0 ? void 0 : onChange({
                name: (_a = searchParams === null || searchParams === void 0 ? void 0 : searchParams.address) !== null && _a !== void 0 ? _a : "",
                lat: searchParams === null || searchParams === void 0 ? void 0 : searchParams.lat,
                lng: searchParams === null || searchParams === void 0 ? void 0 : searchParams.lng,
            }, "fromSearchParams");
        }
    }, []);
    const onSelectResult = (0, react_1.useCallback)((value) => {
        setInputValue(value.name);
        setIsFocused(false);
        if (setSearchParams) {
            setSearchParams({
                lat: value.lat,
                lng: value.lng,
                address: value.name,
            });
        }
        onChange === null || onChange === void 0 ? void 0 : onChange(value, "onSelectResult");
    }, []);
    const onViewportChanged = (0, react_1.useCallback)((viewport) => {
        if (setSearchParams &&
            viewport &&
            viewport.center &&
            viewport.center[0] &&
            viewport.center[1]) {
            setTmpCoordinates(viewport.center);
        }
    }, []);
    const onValidateCoordinates = (0, react_1.useCallback)(() => __awaiter(this, void 0, void 0, function* () {
        setModalIsOpen(false);
        if (tmpCoordinates && setSearchParams) {
            setSearchParams({
                lat: tmpCoordinates[0],
                lng: tmpCoordinates[1],
            });
            const reverseGeocode = yield Api_1.Api.reverseGeocode().get({
                lat: tmpCoordinates[0],
                lng: tmpCoordinates[1],
            });
            setInputValue(reverseGeocode.address);
            setSearchParams({
                lat: tmpCoordinates[0],
                lng: tmpCoordinates[1],
                address: reverseGeocode.address,
            });
            onChange === null || onChange === void 0 ? void 0 : onChange({
                name: reverseGeocode.address,
                lat: tmpCoordinates[0],
                lng: tmpCoordinates[1],
            }, "onValidateCoordinates");
        }
    }), [tmpCoordinates, setSearchParams]);
    const [debouncedInputValue] = (0, use_debounce_1.useDebounce)(inputValue, 500);
    const { data: autocompleteData, isLoading: autocompleteDataIsLoading } = Api_1.Api.autocomplete(debouncedInputValue).useQuery({}, {
        enabled: !!debouncedInputValue && isFocused && debouncedInputValue.length > 2,
    });
    const retrieveCurrentPosition = () => {
        navigator.geolocation.getCurrentPosition((location) => {
            setCurrentPosition(location);
            setTmpCoordinates([
                location.coords.latitude,
                location.coords.longitude,
            ]);
        }, (error) => setCurrentPositionHasError(true));
    };
    return (react_1.default.createElement("div", { className: "place-autocomplete" },
        react_1.default.createElement("div", { className: "input-group placeAutocompleteInput" },
            react_1.default.createElement("input", { type: "text", className: "form-control", value: inputValue, placeholder: "O\u00F9 ?", onChange: (e) => onChangeCallback(e.target.value), onFocus: () => setIsFocused(true), onBlur: () => setTimeout(() => setIsFocused(false), 200) }),
            react_1.default.createElement("div", { className: "input-group-append", onClick: () => {
                    if (inputValue) {
                        setInputValue("");
                        onChange === null || onChange === void 0 ? void 0 : onChange(null, "onClearInput");
                    }
                } },
                react_1.default.createElement("span", { className: "input-group-text" },
                    autocompleteDataIsLoading && (react_1.default.createElement("div", { className: "spinner-grow spinner-grow-sm  text-dark", role: "status" },
                        react_1.default.createElement("span", { className: "sr-only" }, "Loading..."))),
                    !autocompleteDataIsLoading && !!inputValue && (react_1.default.createElement("i", { className: "fas fa-times" })),
                    !autocompleteDataIsLoading && !inputValue && (react_1.default.createElement("i", { className: "fas fa-map-marker-alt" })))),
            noResultBanner && (react_1.default.createElement("div", { className: "no-result-validator" }, "Nous n'avons pas encore d'offre sur cette ville")),
            react_1.default.createElement("div", { className: `dropdown-menu ${isFocused && "show"}` },
                react_1.default.createElement("a", { className: "dropdown-item", href: "#", onClick: () => {
                        retrieveCurrentPosition();
                        setModalIsOpen(true);
                    } },
                    react_1.default.createElement("i", { className: "fas fa-location-arrow m-r-10" }),
                    "\u00C0 proximit\u00E9 de moi"),
                !autocompleteDataIsLoading &&
                    !!autocompleteData &&
                    autocompleteData.results.length > 0 && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("hr", null),
                    autocompleteData.results.map((r) => (react_1.default.createElement("a", { key: r.name, className: "dropdown-item", href: "#", onClick: () => onSelectResult(r) }, r.name))))),
                !autocompleteDataIsLoading &&
                    autocompleteData &&
                    autocompleteData.results.length == 0 && (react_1.default.createElement("span", { className: "dropdown-item" }, "Aucun r\u00E9sultat...")))),
        react_1.default.createElement(reactstrap_1.Modal, { isOpen: modalIsOpen, toggle: () => setModalIsOpen(false), className: "location-modal" },
            react_1.default.createElement(reactstrap_1.ModalHeader, { toggle: () => setModalIsOpen(false) }, "Valider ma position"),
            react_1.default.createElement(reactstrap_1.ModalBody, null,
                currentPositionHasError && (react_1.default.createElement("div", { className: "alert alert-danger" }, "Nous n'avons pas pu r\u00E9cup\u00E9rer vos donn\u00E9es de localisation.")),
                !currentPosition && !currentPositionHasError && (react_1.default.createElement("div", { className: "spinner-grow", role: "status" },
                    react_1.default.createElement("span", { className: "sr-only" }, "Loading..."))),
                currentPosition && !currentPositionHasError && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement("div", { className: "locationMap" },
                        react_1.default.createElement(react_leaflet_1.Map, { center: {
                                lat: currentPosition.coords.latitude,
                                lng: currentPosition.coords.longitude,
                            }, zoom: 14, onViewportChanged: onViewportChanged },
                            react_1.default.createElement(react_leaflet_1.TileLayer, { url: "https://{s}-tiles.locationiq.com/v2/streets/r/{z}/{x}/{y}.vector?key=pk.ea19caf7376ed9efc60d6622f39faead", attribution: `© <a href="https://locationiq.com" target="_blank">LocationIQ Maps</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a></div>` })),
                        react_1.default.createElement("div", { className: "locationMapPin text-primary" },
                            react_1.default.createElement("i", { className: "fas fa-child" }))),
                    react_1.default.createElement("p", { className: "bold text-center text-primary m-b-0 m-t-20" }, "D\u00E9placez la carte jusqu'\u00E0 votre position exacte")))),
            react_1.default.createElement(reactstrap_1.ModalFooter, null,
                !currentPositionHasError && (react_1.default.createElement(react_1.default.Fragment, null,
                    react_1.default.createElement(reactstrap_1.Button, { color: "primary", onClick: onValidateCoordinates }, "Valider"),
                    " ")),
                react_1.default.createElement(reactstrap_1.Button, { color: "secondary", onClick: () => setModalIsOpen(false) }, "Fermer")))));
}
exports.default = PlaceAutocompleteTs;
