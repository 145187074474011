import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { FavoriteSelectors, FavoriteActions } from "../../shared/data/favorite";
import FavoriteButtonContainer from "../../shared/containers/FavoriteButtonContainer";
import { CommonSelectors } from "../../shared/data/common";

class RootContainer extends Component {
  componentDidMount() {
    const { favoriteButtonConfig, fetchOne, userIsConnected } = this.props;
    if (userIsConnected && favoriteButtonConfig) {
      fetchOne(favoriteButtonConfig.className, favoriteButtonConfig.entityId);
    }
  }

  render() {
    const { favoriteButtonConfig } = this.props;

    if (!favoriteButtonConfig) {
      return null;
    }

    return (
      <FavoriteButtonContainer
        className={favoriteButtonConfig.className}
        entityId={favoriteButtonConfig.entityId}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  favoriteButtonConfig: FavoriteSelectors.getButtonConfig(state),
  userIsConnected: CommonSelectors.getUserIsConnected(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchOne: (className, entityId) =>
    dispatch(FavoriteActions.fetchOneFavorite(className, entityId)),
});

RootContainer.propTypes = {
  favoriteButtonConfig: PropTypes.object,
  fetchOne: PropTypes.func.isRequired,
  userIsConnected: PropTypes.bool.isRequired,
};

RootContainer.defaultProps = {
  favoriteButtonConfig: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
