import React from "react";
import PropTypes from "prop-types";
import { FormFeedback, FormGroup, Input, Label, FormText } from "reactstrap";

const TextInput = ({
  input,
  label,
  type,
  helper,
  meta: { touched, error },
  placeholder,
  maxLength,
}) => (
  <FormGroup>
    <Label for={input.name}>{label}</Label>
    <Input
      {...input}
      type={type}
      invalid={!!(touched && error)}
      placeholder={placeholder}
      maxLength={maxLength}
    />
    {touched && error && <FormFeedback>{error}</FormFeedback>}
    {(helper || maxLength) && (
      <FormText>
        {helper}
        {maxLength && (
          <span>
            {maxLength - input.value.length}
            {" caractère(s) restant(s)"}
          </span>
        )}
      </FormText>
    )}
  </FormGroup>
);

TextInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  type: PropTypes.string,
  helper: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
};

TextInput.defaultProps = {
  label: "",
  type: "text",
  placeholder: "",
  helper: "",
  maxLength: null,
};

export default TextInput;
