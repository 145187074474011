import { Set } from "immutable";
import * as FavoriteActionsTypes from "./FavoriteActionsTypes";

export const initialState = {
  favorites: Set(),
  waitingFavorites: Set(),
  isLoading: false,
  buttonConfig: null,
};

const formatFavoriteName = (className, entityId) => `${className}-${entityId}`;

const favoritesFetch = (state) => ({
  ...state,
  isLoading: true,
});

const favoritesReceived = (state, data) => {
  let { favorites } = state;
  data.forEach((e) => {
    favorites = favorites.add(e);
  });

  return {
    ...state,
    isLoading: false,
    favorites,
  };
};

const favoriteAdd = (state, className, entityId) => ({
  ...state,
  waitingFavorites: state.waitingFavorites.add(
    formatFavoriteName(className, entityId)
  ),
});

const favoriteAdded = (state, className, entityId) => ({
  ...state,
  waitingFavorites: state.waitingFavorites.delete(
    formatFavoriteName(className, entityId)
  ),
  favorites: state.favorites.add(formatFavoriteName(className, entityId)),
});

const favoriteRemove = (state, className, entityId) => ({
  ...state,
  waitingFavorites: state.waitingFavorites.add(
    formatFavoriteName(className, entityId)
  ),
});

const favoriteRemoved = (state, className, entityId) => ({
  ...state,
  waitingFavorites: state.waitingFavorites.delete(
    formatFavoriteName(className, entityId)
  ),
  favorites: state.favorites.delete(formatFavoriteName(className, entityId)),
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FavoriteActionsTypes.FAVORITES_FETCH:
      return favoritesFetch(state);
    case FavoriteActionsTypes.FAVORITES_RECEIVED:
      return favoritesReceived(state, action.data);
    case FavoriteActionsTypes.FAVORITE_ADD:
      return favoriteAdd(state, action.className, action.entityId);
    case FavoriteActionsTypes.FAVORITE_ADDED:
      return favoriteAdded(state, action.className, action.entityId);
    case FavoriteActionsTypes.FAVORITE_REMOVE:
      return favoriteRemove(state, action.className, action.entityId);
    case FavoriteActionsTypes.FAVORITE_REMOVED:
      return favoriteRemoved(state, action.className, action.entityId);

    default:
      return state;
  }
};

export default reducer;
