import React, { Component } from "react";
import { Map as LeafletMap, Marker, Popup, TileLayer } from "react-leaflet";

import "leaflet/dist/leaflet.css";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";

class MapWithOnePin extends Component {
  render() {
    const { lat, lng, zoom, dragging, scrollWheelZoom } = this.props;

    const icon = L.divIcon({
      html: ReactDOMServer.renderToString(
        <i className="map-maker fas fa-map-marker-alt color-red" />
      ),
    });

    return (
      <LeafletMap
        className="map-with-one-pin"
        center={[lat, lng]}
        zoom={zoom}
        dragging={dragging}
        scrollWheelZoom={scrollWheelZoom}
      >
        <TileLayer
          url="https://{s}-tiles.locationiq.com/v2/streets/r/{z}/{x}/{y}.vector?key=pk.ea19caf7376ed9efc60d6622f39faead"
          attribution={`© <a href="https://locationiq.com" target="_blank">LocationIQ Maps</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a></div>`}
        />
        <Marker position={[lat, lng]} icon={icon} />
      </LeafletMap>
    );
  }
}

MapWithOnePin.propTypes = {
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  zoom: PropTypes.number,
  dragging: PropTypes.bool,
  scrollWheelZoom: PropTypes.bool,
};

MapWithOnePin.defaultProps = {
  zoom: 13,
  dragging: true,
  scrollWheelZoom: true,
};
export default MapWithOnePin;
