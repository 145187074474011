import ReactOnRails from "react-on-rails";
import AppSearchForm from "./AppSearchForm";
import configureStore from "./configureStore";

const searchFormStore = configureStore;

console.log("Ready");

ReactOnRails.registerStore({ searchFormStore });
ReactOnRails.register({ AppSearchForm });
