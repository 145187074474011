const messages = {
  invalidForm: "Le formulaire contient des champs invalides ou manquants",
  errorOccurred: "Une erreur est survenue, veuillez réessayer plus tard.",
};

const getFieldErrors = (field) => {
  if (field.errors && field.errors.length > 0) {
    // eslint-disable-next-line prefer-destructuring
    return field.errors[0];
  }

  if (field.children) {
    const fieldErrors = {};

    Object.entries(field.children).forEach(([name, child]) => {
      const errors = getFieldErrors(child);
      if (errors) {
        fieldErrors[name] = errors;
      }
    });

    return Object.keys(fieldErrors).length > 0 ? fieldErrors : null;
  }

  return null;
};

const formatSymfonyFormErrorResponse = (response) => {
  let formErrors = {};

  if (response.message) {
    // eslint-disable-next-line no-underscore-dangle
    formErrors._error = messages.invalidForm;
  }

  if (response.errors) {
    formErrors = Object.assign(formErrors, getFieldErrors(response.errors));
  }

  return formErrors;
};

const formatSymfonyServerErrorResponse = (response) => {
  const message =
    (response.error && response.error.message) || messages.errorOccurred;

  return {
    _error: message,
  };
};

const formHelper = {
  formatSymfonyFormErrorResponse,
  formatSymfonyServerErrorResponse,
};

export default formHelper;
