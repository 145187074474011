const createHttpError = (errorMessage, errorStatusCode) => {
  class HttpError extends Error {
    constructor(message, code) {
      super();

      const proto = Object.getPrototypeOf(this);
      proto.name = "HttpError";
      proto.message = message;

      this.code = code;
    }

    toString() {
      return `${this.message} (${this.code})`;
    }
  }

  return new HttpError(errorMessage, errorStatusCode);
};

const handleResponse = (response) =>
  response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      return Promise.reject(
        createHttpError(
          (data && data.message) || response.statusText,
          response.status
        )
      );
    }

    return data;
  });

const handleFormResponse = (response) =>
  response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok && response.status !== 400) {
      return Promise.reject(
        createHttpError(
          (data && data.message) || response.statusText,
          response.status
        )
      );
    }

    return data;
  });

const defaultHeaders = (contentType = "application/json") => {
  const headers = {};

  if (contentType) {
    headers["Content-Type"] = contentType;
  }

  return headers;
};

const apiHelper = {
  handleResponse,
  handleFormResponse,
  defaultHeaders,
};

export default apiHelper;
