import * as AutocompleteActionsTypes from "./AutocompleteActionsTypes";

export const initialState = {
  result: null,
  noResult: false,
  value: "",
  isLoading: false,
  selectedPlace: null,

  locationAlreadyFetch: false,
  locationIsLoading: false,
  location: null,
  locationResponse: null,
  locationError: null,

  shouldReverseGeocode: false,

  lastSelectedPlaces: [],
};

const autocompleteFetch = (state, value) => ({
  ...state,
  isLoading: !!value,
  result: value ? state.result : null,
  noResult: false,
  value,
});

const autocompleteReceived = (state, data) => ({
  ...state,
  isLoading: false,
  result: data,
});

const autocompleteError = (state) => ({
  ...state,
  isLoading: false,
  result: null,
  noResult: true,
});

const autocompleteSelect = (state, selectedPlace) => ({
  ...state,
  value: selectedPlace.name || state.value,
  selectedPlace,
  location: { lat: selectedPlace.lat, lng: selectedPlace.lng },
});

const autocompleteUnselect = (state) => ({
  ...state,
  value: "",
  result: null,
  location: null,
});

const autocompleteReset = () => ({
  ...initialState,
});

const fetchLocation = (state) => ({
  ...state,
  // locationIsLoading: state.location === null,
  locationIsLoading: true,
});

const locationReceived = (state, data) => ({
  ...state,
  locationIsLoading: false,
  locationResponse: data,
  locationAlreadyFetch: true,
});

const locationError = (state, error) => ({
  ...state,
  locationIsLoading: false,
  locationError: error,
  locationAlreadyFetch: true,
});

const editCoordinates = (state, coordinates) => ({
  ...state,
  location: coordinates,
  shouldReverseGeocode: true,
});

const reverseGeocodeFetch = (state) => ({
  ...state,
  isLoading: true,
});

const reverseGeocodeReceived = (state, data) => ({
  ...state,
  shouldReverseGeocode: false,
  isLoading: false,
  value: data.address,
  result: null,
});

const lastSelectedPlacesAdd = (state, place) => {
  const lastSelectedPlaces = [
    ...state.lastSelectedPlaces
      .filter((p) => p.name !== place.name)
      .slice(0, 4),
  ];
  return {
    ...state,
    lastSelectedPlaces: [place, ...lastSelectedPlaces],
  };
};

const lastSelectedPlacesRetrieved = (state, places) => ({
  ...state,
  lastSelectedPlaces: places,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AutocompleteActionsTypes.AUTOCOMPLETE_FETCH:
      return autocompleteFetch(state, action.search);
    case AutocompleteActionsTypes.AUTOCOMPLETE_RECEIVED:
      return autocompleteReceived(state, action.data);
    case AutocompleteActionsTypes.AUTOCOMPLETE_ERROR:
      return autocompleteError(state);
    case AutocompleteActionsTypes.AUTOCOMPLETE_UNSELECT:
      return autocompleteUnselect(state);
    case AutocompleteActionsTypes.AUTOCOMPLETE_SELECT:
      return autocompleteSelect(state, action.place);
    case AutocompleteActionsTypes.AUTOCOMPLETE_RESET:
      return autocompleteReset(state);
    case AutocompleteActionsTypes.LOCATION_FETCH:
      return fetchLocation(state);
    case AutocompleteActionsTypes.LOCATION_RECEIVED:
      return locationReceived(state, action.data);
    case AutocompleteActionsTypes.LOCATION_ERROR:
      return locationError(state, action.error);
    case AutocompleteActionsTypes.LOCATION_EDIT:
      return editCoordinates(state, action.coordinates);
    case AutocompleteActionsTypes.REVERSE_GEOCODE_FETCH:
      return reverseGeocodeFetch(state);
    case AutocompleteActionsTypes.REVERSE_GEOCODE_RECEIVED:
      return reverseGeocodeReceived(state, action.data);
    case AutocompleteActionsTypes.LAST_SELECTED_PLACES_ADD:
      return lastSelectedPlacesAdd(state, action.place);
    case AutocompleteActionsTypes.LAST_SELECTED_PLACES_RETRIEVED:
      return lastSelectedPlacesRetrieved(state, action.places);
    default:
      return state;
  }
};

export default reducer;
