import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { BookingActions, BookingSelectors } from "../../shared/data/booking";
import CancellationPolicy from "../components/CancellationPolicy";
import { CommonActions, CommonSelectors } from "../../shared/data/common";
import RegistrationForm from "../../shared/components/forms/RegistrationForm";
import NumberOfUnitsSelect from "../components/NumberOfUnitsSelect";

const messages = {
  submit: "Je confirme",
  submitWithoutBooking: "J'y vais",
  register: "S'inscrire",
  login: "J'ai déjà un compte",
  withFacebook: "Continuer avec Facebook",
  withGoogle: "Continuer avec Google",
};

class BookingModalContainer extends Component {
  setNumberOfUnitsAndAthletes(
    numberOfUnits,
    numberOfAthletes,
    notifyAthletes,
    openContact
  ) {
    const {
      setNumberOfUnits,
      setNumberOfAthletes,
      setNotifyAthletes,
      isConnected,
      session,
    } = this.props;

    setNumberOfUnits(numberOfUnits);
    setNumberOfAthletes(numberOfAthletes);
    setNotifyAthletes(notifyAthletes);

    if (isConnected) {
      window.location.href = `/book/${
        session.id
      }/${numberOfUnits}?numberOfAthletes=${
        numberOfAthletes ?? null
      }&notifyAthletes=${notifyAthletes ? 1 : 0}&openContact=${
        openContact ? 1 : 0
      }`;
    }
  }
  render() {
    const {
      session,
      bookingModalIsOpen,
      closeBookingModal,
      isConnected,
      registrationFormSubmit,
      numberOfUnits,
      numberOfAthletes,
      notifyAthletes,
      isInIframe,
    } = this.props;

    if (!session) {
      return null;
    }

    return (
      <div>
        <Modal
          className="booking-modal"
          isOpen={bookingModalIsOpen}
          toggle={closeBookingModal}
        >
          <ModalHeader toggle={closeBookingModal}>
            {session.withoutBooking ? "Information" : "Réservation"}
          </ModalHeader>
          <ModalBody>
            <div>
              {!numberOfUnits && !session.withoutBooking && (
                <p className="wording-line1">Je choisis</p>
              )}
              {!numberOfUnits && (
                <NumberOfUnitsSelect
                  onSelect={(a, b, c, d) =>
                    this.setNumberOfUnitsAndAthletes(a, b, c, d)
                  }
                  actionMessage={
                    session.withoutBooking
                      ? messages.submitWithoutBooking
                      : messages.submit
                  }
                  unitName={session.unit.pluralName}
                  athleteUnitName={session.athleteUnit.name}
                  athleteUnitPluralName={session.athleteUnit.pluralName}
                  minNumberOfUnits={session.minUnitsByBooking}
                  maxNumberOfUnits={Math.min(
                    session.remainingSeats,
                    session.maxUnitsByBooking
                  )}
                  minNumberOfAthleteByUnit={session.minNumberOfAthleteByUnit}
                  maxNumberOfAthleteByUnit={
                    session.maxNumberOfAthleteByUnit ??
                    session.minNumberOfAthleteByUnit
                  }
                  forceParticipantData={session.forceParticipantData}
                />
              )}
              {!numberOfUnits &&
                !session.isOnline &&
                !session.paymentLinkEnabled && (
                  <p className="wording-line2">
                    Le paiement se fait directement auprès de {session.laps}{" "}
                    sans aucune commission.
                  </p>
                )}
            </div>
            {numberOfUnits && !isConnected && (
              <React.Fragment>
                <RegistrationForm
                  onSubmit={(data) =>
                    registrationFormSubmit(
                      data,
                      `/book/${session.id}/${numberOfUnits}?numberOfAthletes=${
                        numberOfAthletes ?? null
                      }&notifyAthletes=${notifyAthletes ? 1 : 0}`
                    )
                  }
                />
                <hr className="m-t-20 m-b-20" />
                <Button
                  color="outline-primary"
                  block
                  href={`/login?_target_path=/book/${
                    session.id
                  }/${numberOfUnits}?numberOfAthletes=${
                    numberOfAthletes ?? null
                  }%26notifyAthletes=${notifyAthletes ? 1 : 0}`}
                  target={isInIframe ? "_blank" : "_self"}
                >
                  {messages.login}
                </Button>
                <hr className="m-t-20 m-b-20" />
                <a
                  className="btn btn-block btn-outline-facebook"
                  href={`/connect/facebook?_target_path=/book/${
                    session.id
                  }/${numberOfUnits}?numberOfAthletes=${
                    numberOfAthletes ?? null
                  }%26notifyAthletes=${notifyAthletes ? 1 : 0}`}
                  target={isInIframe ? "_blank" : "_self"}
                >
                  <i className="fab fa-facebook" />
                  {messages.withFacebook}
                </a>
                <a
                  className="btn btn-block btn-outline-google"
                  href={`/connect/google?_target_path=/book/${
                    session.id
                  }/${numberOfUnits}?numberOfAthletes=${
                    numberOfAthletes ?? null
                  }%26notifyAthletes=${notifyAthletes ? 1 : 0}`}
                  target={isInIframe ? "_blank" : "_self"}
                >
                  <i className="fab fa-google" />
                  {messages.withGoogle}
                </a>
              </React.Fragment>
            )}
            {!session.paymentLinkEnabled && <CancellationPolicy />}
            {session.paymentLinkEnabled && (
              <div className="cancellation-policy">
                <p>
                  Conditions de paiement et d'annulation :
                  <br />
                  <span style={{ whiteSpace: "pre-line" }}>
                    {session.paymentLinkExplanation}
                  </span>
                </p>
              </div>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  bookingModalIsOpen: BookingSelectors.bookingModalIsOpen(state),
  session: BookingSelectors.getSession(state),
  numberOfUnits: BookingSelectors.getNumberOfUnits(state),
  numberOfAthletes: BookingSelectors.getNumberOfAthletes(state),
  notifyAthletes: BookingSelectors.getNotifyAthletes(state),
  isConnected: CommonSelectors.getUserIsConnected(state),
  isInIframe: CommonSelectors.getInIframe(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeBookingModal: () => dispatch(BookingActions.closeBookingModal()),
  setNumberOfUnits: (numberOfUnits) =>
    dispatch(BookingActions.setNumberOfUnits(numberOfUnits)),
  setNumberOfAthletes: (numberOfAthletes) =>
    dispatch(BookingActions.setNumberOfAthletes(numberOfAthletes)),
  setNotifyAthletes: (v) => dispatch(BookingActions.setNotifyAthletes(v)),
  registrationFormSubmit: (data, targetPath) =>
    dispatch(CommonActions.registrationFormSubmit(data, targetPath)),
});

BookingModalContainer.propTypes = {
  bookingModalIsOpen: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  session: PropTypes.object,
  numberOfUnits: PropTypes.number,
  numberOfAthletes: PropTypes.number,
  notifyAthletes: PropTypes.number,
  closeBookingModal: PropTypes.func.isRequired,
  registrationFormSubmit: PropTypes.func.isRequired,
  setNumberOfUnits: PropTypes.func.isRequired,
  setNumberOfAthletes: PropTypes.func.isRequired,
  setNotifyAthletes: PropTypes.func.isRequired,
  isInIframe: PropTypes.bool.isRequired,
};

BookingModalContainer.defaultProps = {
  session: null,
  numberOfUnits: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingModalContainer);
