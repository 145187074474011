import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { FavoriteActions, FavoriteSelectors } from "../data/favorite";
import { CommonSelectors } from "../data/common";

class FavoriteButtonContainer extends Component {
  render() {
    const {
      entityId,
      className,
      addFavorite,
      removeFavorite,
      favorites,
      waitingFavorites,
      userIsConnected,
    } = this.props;

    if (!userIsConnected) {
      return (
        <div className="favorite-button favorite-button-notlike">
          <i
            className="far fa-star"
            onClick={(e) => {
              e.preventDefault();
              document.location.href = "/register/?from=addFavorite";
            }}
          />
        </div>
      );
    }

    if (waitingFavorites.has(`${className}-${entityId}`)) {
      return (
        <div className="favorite-button favorite-button-loading">
          <i className="fas fa-star" />
        </div>
      );
    }

    if (favorites.has(`${className}-${entityId}`)) {
      return (
        <div className="favorite-button favorite-button-like">
          <i
            className="fas fa-star"
            onClick={(e) => {
              e.preventDefault();
              removeFavorite(className, entityId);
            }}
          />
        </div>
      );
    }

    return (
      <div className="favorite-button favorite-button-notlike">
        <i
          className="far fa-star"
          onClick={(e) => {
            e.preventDefault();
            addFavorite(className, entityId);
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  favorites: FavoriteSelectors.getFavorites(state),
  waitingFavorites: FavoriteSelectors.getWaitingFavorites(state),
  userIsConnected: CommonSelectors.getUserIsConnected(state),
});

const mapDispatchToProps = (dispatch) => ({
  addFavorite: (className, entityId) =>
    dispatch(FavoriteActions.addFavorite(className, entityId)),
  removeFavorite: (className, entityId) =>
    dispatch(FavoriteActions.removeFavorite(className, entityId)),
});

FavoriteButtonContainer.propTypes = {
  entityId: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,

  favorites: PropTypes.any.isRequired,
  waitingFavorites: PropTypes.any.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  userIsConnected: PropTypes.bool.isRequired,
};

FavoriteButtonContainer.defaultProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FavoriteButtonContainer);
