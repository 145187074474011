import React from "react";
import PropTypes from "prop-types";
import {
  FormFeedback,
  FormGroup,
  CustomInput,
  Label,
  FormText,
} from "reactstrap";

const CheckboxInput = ({ input, label, helper, meta: { touched, error } }) => (
  <FormGroup check className="mb-3">
    <Label check>
      <CustomInput
        {...input}
        type="checkbox"
        id={input.name}
        invalid={!!(touched && error)}
        label={label}
      />
    </Label>
    {touched && error && (
      <FormFeedback className="force-display-invalid-feedback">
        {error}
      </FormFeedback>
    )}
    {helper && <FormText>{helper}</FormText>}
  </FormGroup>
);

CheckboxInput.propTypes = {
  input: PropTypes.object.isRequired,
  label: PropTypes.string,
  helper: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

CheckboxInput.defaultProps = {
  label: "",
  helper: "",
};

export default CheckboxInput;
