import * as BookingActionsTypes from "./BookingActionsTypes";

export const openBookingModal = (session) => ({
  type: BookingActionsTypes.BOOKING_MODAL_OPEN,
  session,
});

export const closeBookingModal = () => ({
  type: BookingActionsTypes.BOOKING_MODAL_CLOSE,
});

export const openWaitingListModal = (session) => ({
  type: BookingActionsTypes.BOOKING_WAITING_LIST_MODAL_OPEN,
  session,
});

export const closeWaitingListModal = () => ({
  type: BookingActionsTypes.BOOKING_WAITING_LIST_MODAL_CLOSE,
});

export const openToBookInformationModal = (session) => ({
  type: BookingActionsTypes.BOOKING_TO_BOOK_INFORMATION_MODAL_OPEN,
  session,
});

export const closeToBookInformationModal = () => ({
  type: BookingActionsTypes.BOOKING_TO_BOOK_INFORMATION_MODAL_CLOSE,
});

export const bookingFormSubmit = (data) => ({
  type: BookingActionsTypes.BOOKING_FORM_SUBMIT,
  data,
});

export const bookingFormSuccess = () => ({
  type: BookingActionsTypes.BOOKING_FORM_SUCCESS,
});

export const setNumberOfUnits = (numberOfUnits) => ({
  type: BookingActionsTypes.BOOKING_FORM_SET_NUMBER_OF_UNITS,
  numberOfUnits,
});

export const setNumberOfAthletes = (numberOfAthletes) => ({
  type: BookingActionsTypes.BOOKING_FORM_SET_NUMBER_OF_ATHLETES,
  numberOfAthletes,
});

export const setNotifyAthletes = (notifyAthletes) => ({
  type: BookingActionsTypes.BOOKING_FORM_SET_NOTIFY_ATHLETES,
  notifyAthletes,
});
