import { put, select, takeLatest, call } from "redux-saga/effects";
import axios from "axios";
import { createBrowserHistory as createHistory } from "history";
import QueryString from "query-string";
import { SearchActionsTypes, SearchSelectors, SearchActions } from "./index";
import { CommonSelectors } from "../common";
import { BookingActions } from "../booking";
import {
  AutocompleteActionsTypes,
  AutocompleteSelectors,
} from "../autocomplete";
import { getParamHideForSubscriber } from "./SearchSelectors";

export function* searchInit() {
  // yield put(SearchActions.initSearchForm());
  // yield put(SearchActions.fetchSeances(0));
  // yield put(SearchActions.fetchAvailableSports());
}

export function* seancesFetchSaga({ page }) {
  const sportId = yield select(SearchSelectors.getParamSportId);
  const organizationId = yield select(SearchSelectors.getParamOrganizationId);
  const date = yield select(SearchSelectors.getParamDate);
  const time = yield select(SearchSelectors.getParamTime);
  const activityTemplateId = yield select(
    SearchSelectors.getParamActivityTemplateId
  );
  const onlineOnly = yield select(SearchSelectors.getParamOnlineOnly);
  const partnerId = yield select(SearchSelectors.getPartnerId);
  const availableOnly = yield select(SearchSelectors.getParamAvailableOnly);
  const hideForSubscriber = yield select(
    SearchSelectors.getParamHideForSubscriber
  );
  const urlPrefix = yield select(CommonSelectors.getUrlPrefix);
  const platform = yield select(CommonSelectors.getPlatform);
  const location = yield select(AutocompleteSelectors.getLocation);
  const address = yield select(AutocompleteSelectors.getAutocompleteValue);

  const queryParams = {
    date,
    after: time,
    laps: organizationId,
    sport: sportId,
    activity: activityTemplateId,
    page,
    onlineOnly: onlineOnly ? 1 : 0,
    availableOnly: availableOnly ? 1 : 0,
    hideForSubscriber: hideForSubscriber ? 1 : 0,
    lat: location && location.lat,
    lng: location && location.lng,
    address,
    partner: partnerId,
  };

  let shouldFetch = true;
  // Don't fetch result if no minimum params is set
  if (shouldFetch && !location && !organizationId && !partnerId) {
    shouldFetch = false;
    yield put(SearchActions.cancelFetchSeances());
  }

  if (shouldFetch) {
    const data = yield axios
      .get("/search/api/seances", {
        params: queryParams,
      })
      .then((response) => response.data);

    yield put(SearchActions.receivedSeances(data, page));
  }

  // Change URL
  if (page === 0) {
    const history = createHistory();
    Object.keys(queryParams).forEach(
      (k) =>
        !queryParams[k] && queryParams[k] !== undefined && delete queryParams[k]
    );
    delete queryParams.page;
    yield call(
      history.replace,
      `${urlPrefix || "/"}?${QueryString.stringify(queryParams, {
        arrayFormat: "bracket",
      })}`,
      history.state
    );
  }
}

export function* searchParamSportSetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* searchParamLapsSetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* searchParamDateSetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* searchParamTimeSetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* searchParamActivityTemplateSetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* searchParamOnlineOnlySetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* searchLocationSetSaga() {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm) {
    yield put(SearchActions.fetchSeances(0));
  }

  yield put(SearchActions.fetchAvailableSports());
}

export function* toggleFilterModalSaga({ value }) {
  const isOnlyForm = yield select(SearchSelectors.isOnlyForm);
  if (!isOnlyForm && !value) {
    yield put(SearchActions.fetchSeances(0));
  }
}

export function* currentSessionFetchSaga({ sessionId }) {
  const data = yield axios
    .get(`/search/api/session/${sessionId}`)
    .catch(() => {
      window.location.replace("/activites/search");
    })
    .then((response) => response.data);
  yield put(SearchActions.receivedCurrentSession(data));
}

export function* currentSessionReceivedSaga() {
  const urlParams = new URLSearchParams(window.location.search);
  if (urlParams.get("openBookingModal")) {
    const session = yield select(SearchSelectors.getCurrentSession);
    yield put(BookingActions.openBookingModal(session));
  }

  if (urlParams.get("openToBookInformationModal")) {
    const session = yield select(SearchSelectors.getCurrentSession);
    yield put(BookingActions.openToBookInformationModal(session));
  }
}

export function* availableSportsFetchSaga() {
  const location = yield select(AutocompleteSelectors.getLocation);
  const address = yield select(AutocompleteSelectors.getAutocompleteValue);

  if (!location) {
    return;
  }

  const queryParams = {
    lat: location && location.lat,
    lng: location && location.lng,
    address,
  };

  const data = yield axios
    .get("/search/api/available-sports", {
      params: queryParams,
    })
    .then((response) => response.data);
  yield put(SearchActions.receivedAvailableSports(data));
}

export const searchSagas = [
  takeLatest(SearchActionsTypes.SEARCH_INIT, searchInit),

  takeLatest(SearchActionsTypes.SEANCES_FETCH, seancesFetchSaga),

  takeLatest(SearchActionsTypes.CURRENT_SESSION_FETCH, currentSessionFetchSaga),
  takeLatest(
    SearchActionsTypes.CURRENT_SESSION_RECEIVED,
    currentSessionReceivedSaga
  ),

  takeLatest(
    SearchActionsTypes.SEARCH_PARAM_SPORT_SET,
    searchParamSportSetSaga
  ),
  takeLatest(SearchActionsTypes.SEARCH_PARAM_LAPS_SET, searchParamLapsSetSaga),
  takeLatest(SearchActionsTypes.SEARCH_PARAM_DATE_SET, searchParamDateSetSaga),
  takeLatest(SearchActionsTypes.SEARCH_PARAM_TIME_SET, searchParamTimeSetSaga),
  takeLatest(
    SearchActionsTypes.SEARCH_PARAM_ACTIVITY_TEMPLATE_SET,
    searchParamActivityTemplateSetSaga
  ),
  takeLatest(
    SearchActionsTypes.SEARCH_PARAM_ONLINE_ONLY_SET,
    searchParamOnlineOnlySetSaga
  ),
  takeLatest(SearchActionsTypes.FILTER_MODAL_TOGGLE, toggleFilterModalSaga),

  takeLatest(
    SearchActionsTypes.AVAILABLE_SPORTS_FETCH,
    availableSportsFetchSaga
  ),

  takeLatest(AutocompleteActionsTypes.LOCATION_EDIT, searchLocationSetSaga),
  takeLatest(
    AutocompleteActionsTypes.AUTOCOMPLETE_SELECTED,
    searchLocationSetSaga
  ),
];
