import React, { Component } from "react";
import PropTypes from "prop-types";
import { FormGroup, Label, Input, Button } from "reactstrap";

class NumberOfUnitsSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfUnits: props.minNumberOfUnits,
      numberOfAthletes: props.minNumberOfAthleteByUnit
        ? props.minNumberOfUnits * props.minNumberOfAthleteByUnit
        : null,
    };
  }

  onChangeNumberOfUnits(event) {
    const { minNumberOfAthleteByUnit } = this.props;

    this.setState({
      numberOfUnits: event.target.value,
      numberOfAthletes: minNumberOfAthleteByUnit
        ? event.target.value * minNumberOfAthleteByUnit
        : null,
    });
  }

  onChangeNumberOfAthletes(event) {
    this.setState({
      numberOfAthletes: event.target.value,
    });
  }

  render() {
    const {
      minNumberOfUnits,
      maxNumberOfUnits,
      minNumberOfAthleteByUnit,
      maxNumberOfAthleteByUnit,
      onSelect,
      unitName,
      athleteUnitName,
      athleteUnitPluralName,
      actionMessage,
      forceParticipantData,
    } = this.props;
    const { numberOfUnits, numberOfAthletes } = this.state;

    const computedMinNumberOfAthleteByUnit =
      minNumberOfAthleteByUnit * numberOfUnits;
    const computedMaxNumberOfAthleteByUnit =
      maxNumberOfAthleteByUnit * numberOfUnits;

    return (
      <div className="numberOfUnitsSelect">
        <FormGroup>
          <Label for="selectNumberOfUnits">Nombre de {unitName}</Label>
          <Input
            type="select"
            name="selectNumberOfUnits"
            value={numberOfUnits}
            onChange={(e) => this.onChangeNumberOfUnits(e)}
          >
            {[...Array(maxNumberOfUnits - minNumberOfUnits + 1).keys()].map(
              (v) => (
                <option key={v + minNumberOfUnits}>
                  {v + minNumberOfUnits}
                </option>
              )
            )}
          </Input>
          {minNumberOfAthleteByUnit && (
            <React.Fragment>
              <Label for="selectNumberOfAthletes" className="mt-5">
                Nombre de {athleteUnitPluralName}
              </Label>
              <Input
                type="select"
                name="selectNumberOfAthletes"
                value={numberOfAthletes}
                onChange={(e) => this.onChangeNumberOfAthletes(e)}
              >
                {[
                  ...Array(
                    computedMaxNumberOfAthleteByUnit -
                      computedMinNumberOfAthleteByUnit +
                      1
                  ).keys(),
                ].map((v) => (
                  <option key={v + computedMinNumberOfAthleteByUnit}>
                    {v + computedMinNumberOfAthleteByUnit}
                  </option>
                ))}
              </Input>
            </React.Fragment>
          )}
        </FormGroup>
        {minNumberOfAthleteByUnit && !!forceParticipantData && (
          <React.Fragment>
            <Button
              color="primary"
              block
              onClick={() =>
                onSelect(numberOfUnits, numberOfAthletes, true, false)
              }
            >
              Continuer
            </Button>
          </React.Fragment>
        )}
        {minNumberOfAthleteByUnit && !forceParticipantData && (
          <React.Fragment>
            <Button
              color="primary"
              block
              onClick={() =>
                onSelect(numberOfUnits, numberOfAthletes, true, false)
              }
            >
              Je souhaite informer les autres {athleteUnitPluralName}
            </Button>
            <Button
              color="primary mt-2"
              block
              onClick={() =>
                onSelect(numberOfUnits, numberOfAthletes, true, true)
              }
            >
              Je souhaite informer un de mes contacts
            </Button>
            <Button
              color="outline-primary mt-2"
              block
              onClick={() =>
                onSelect(numberOfUnits, numberOfAthletes, false, false)
              }
            >
              Je les informe plus tard
            </Button>
          </React.Fragment>
        )}
        {!minNumberOfAthleteByUnit && (
          <Button
            color="outline-primary"
            block
            onClick={() =>
              onSelect(numberOfUnits, numberOfAthletes, false, false)
            }
          >
            {actionMessage}
          </Button>
        )}
      </div>
    );
  }
}

NumberOfUnitsSelect.propTypes = {
  minNumberOfUnits: PropTypes.number,
  maxNumberOfUnits: PropTypes.number,
  minNumberOfAthleteByUnit: PropTypes.number,
  maxNumberOfAthleteByUnit: PropTypes.number,
  actionMessage: PropTypes.string.isRequired,
  athleteUnitName: PropTypes.string.isRequired,
  athleteUnitPluralName: PropTypes.string.isRequired,
  unitName: PropTypes.string.isRequired,
  forceParticipantData: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};
NumberOfUnitsSelect.defaultProps = {
  maxNumberOfUnits: 10,
};

export default NumberOfUnitsSelect;
