import * as SearchActionsTypes from "./SearchActionsTypes";
import moment from "moment";

export const initialState = {
  selects: {
    organizations: [],
    sports: [],
    activityTemplates: [],
  },
  availableSports: null,
  showMobileMap: false,
  showFilterModal: false,
  placeHovered: null,
  seances: null,
  alternativeSports: [],
  courts: [],
  activityTemplates: [],
  places: null,
  seancesAreLoading: false,
  currentSession: null,
  currentSessionIsLoading: false,
  currentPositionIsLoading: false,
  currentPosition: null,
  mode: null,
  bookings: [],
  params: {
    sportId: null,
    organizationId: null,
    activityTemplateId: null,
    date: null,
    time: null,
    onlineOnly: false,
    availableOnly: false,
    partnerId: null,
    hideForSubscriber: false,
  },
  isOnlyForm: false,
  page: 0,
};
const searchInit = (state) => {
  let date = state.params.date
    ? moment(state.params.date)
    : moment({ hour: 0, minute: 0, seconds: 0, milliseconds: 0 });
  let time = parseInt(state.params.time, 10) || 6;
  let now = moment();

  if (
    now.isAfter(moment().add(1, "days").startOf("day").subtract("2", "hours"))
  ) {
    now = moment().add(1, "days").startOf("day");
  }

  if (date.isBefore(now)) {
    date = now;

    if (parseInt(date.format("H"), 10) > time) {
      time = parseInt(date.format("H"), 10) + 1;
    }
  }

  return {
    ...state,
    params: {
      ...state.params,
      date: date.format("YYYY-MM-DD"),
      time,
    },
  };
};

const seancesFetch = (state, page) => ({
  ...state,
  seancesAreLoading: page === 0 ? true : state.seancesAreLoading,
  seances: page === 0 ? null : state.seances,
  places: page === 0 ? null : state.places,
  hasMore: false,
});

const seancesReceived = (state, data, page) => ({
  ...state,
  foo: "bar",
  mode: data.mode,
  seances: page === 0 ? data.sessions : [...state.seances, ...data.sessions],
  places: page === 0 ? data.places : state.places,
  alternativeSports:
    page === 0 ? data.alternativeSports : state.alternativeSports,
  courts: page === 0 ? data.courts : state.courts,
  activityTemplates:
    page === 0 ? data.activityTemplates : state.activityTemplates,
  seancesAreLoading: false,
  hasMore: data.sessions.length === 20,
});

const seancesFetchCancel = (state) => ({
  ...state,
  seancesAreLoading: false,
});

const currentSessionFetch = (state) => ({
  ...state,
  currentSession: null,
  currentSessionIsLoading: true,
});

const currentSessionReceived = (state, data) => ({
  ...state,
  currentSession: data,
  currentSessionIsLoading: false,
});

const currentSessionError = (state) => ({
  ...state,
  currentSessionIsLoading: false,
});

const mapToggle = (state, value) => ({
  ...state,
  showMobileMap: value,
});

const filterModalToggle = (state, value) => ({
  ...state,
  showFilterModal: value,
});

const placeHoveredSet = (state, placeId) => ({
  ...state,
  placeHovered: placeId,
});

const placeHoveredReset = (state) => ({
  ...state,
  placeHovered: null,
});

const searchParamSportSet = (state, sportId) => ({
  ...state,
  params: {
    ...state.params,
    onlineOnly: null,
    sportId,
  },
});

const searchParamLapsSet = (state, organizationId) => ({
  ...state,
  params: {
    ...state.params,
    organizationId,
  },
});

const searchParamDateSet = (state, date) => {
  const now = moment();
  const dateMoment = moment(date);
  let time = 6;

  if (now.isSame(dateMoment, "day")) {
    if (parseInt(now.format("H"), 10) > 6) {
      time = parseInt(now.format("H"), 10) + 1;
    }
  }

  return {
    ...state,
    params: {
      ...state.params,
      date,
      time,
    },
  };
};

const searchParamTimeSet = (state, time) => ({
  ...state,
  params: {
    ...state.params,
    time,
  },
});

const searchParamActivityTemplateSet = (state, templateId) => ({
  ...state,
  params: {
    ...state.params,
    activityTemplateId: templateId,
  },
});

const searchParamOnlineOnlySet = (state, value) => ({
  ...state,
  params: {
    ...state.params,
    sportId: null,
    onlineOnly: value,
  },
});

const searchParamAvailableOnlySet = (state, value) => ({
  ...state,
  params: {
    ...state.params,
    availableOnly: value,
  },
});

const searchParamHideForSubscriberSet = (state, value) => ({
  ...state,
  params: {
    ...state.params,
    hideForSubscriber: value,
  },
});

const availableSportsReceived = (state, data) => ({
  ...state,
  availableSports: data,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SearchActionsTypes.SEARCH_INIT:
      return searchInit(state);

    case SearchActionsTypes.SEANCES_FETCH:
      return seancesFetch(state, action.page);

    case SearchActionsTypes.SEANCES_RECEIVED:
      return seancesReceived(state, action.data, action.page);

    case SearchActionsTypes.SEANCES_FETCH_CANCEL:
      return seancesFetchCancel(state);

    case SearchActionsTypes.CURRENT_SESSION_FETCH:
      return currentSessionFetch(state);

    case SearchActionsTypes.CURRENT_SESSION_RECEIVED:
      return currentSessionReceived(state, action.data);

    case SearchActionsTypes.CURRENT_SESSION_ERROR:
      return currentSessionError(state);

    case SearchActionsTypes.MOBILE_MAP_TOGGLE:
      return mapToggle(state, action.value);

    case SearchActionsTypes.FILTER_MODAL_TOGGLE:
      return filterModalToggle(state, action.value);

    case SearchActionsTypes.PLACE_HOVERED_SET:
      return placeHoveredSet(state, action.placeId);

    case SearchActionsTypes.PLACE_HOVERED_RESET:
      return placeHoveredReset(state);

    case SearchActionsTypes.SEARCH_PARAM_SPORT_SET:
      return searchParamSportSet(state, action.sportId);

    case SearchActionsTypes.SEARCH_PARAM_LAPS_SET:
      return searchParamLapsSet(state, action.organizationId);

    case SearchActionsTypes.SEARCH_PARAM_DATE_SET:
      return searchParamDateSet(state, action.date);

    case SearchActionsTypes.SEARCH_PARAM_TIME_SET:
      return searchParamTimeSet(state, action.time);

    case SearchActionsTypes.SEARCH_PARAM_ACTIVITY_TEMPLATE_SET:
      return searchParamActivityTemplateSet(state, action.templateId);

    case SearchActionsTypes.SEARCH_PARAM_ONLINE_ONLY_SET:
      return searchParamOnlineOnlySet(state, action.value);

    case SearchActionsTypes.SEARCH_PARAM_AVAILABLE_ONLY_SET:
      return searchParamAvailableOnlySet(state, action.value);

    case SearchActionsTypes.SEARCH_PARAM_HIDE_FOR_SUBSCRIBER_SET:
      return searchParamHideForSubscriberSet(state, action.value);

    case SearchActionsTypes.AVAILABLE_SPORTS_RECEIVED:
      return availableSportsReceived(state, action.data);

    default:
      return state;
  }
};

export default reducer;
