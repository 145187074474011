import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Row,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
} from "reactstrap";

class AddToCalendarModal extends Component {
  render() {
    const { session, isOpen, closeCallback } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={closeCallback}
        className="addToCalendarModal"
        size="lg"
      >
        <ModalHeader toggle={closeCallback}>Ajouter au calendrier</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12" md="4">
              <div className="card bg-light text-center">
                <div className="card-body">
                  <img
                    src="/build/images/addToCalendar/outlook.png"
                    alt="Ajouter dans Outlook"
                    width="100"
                  />
                  <h5 className="card-title">Outlook</h5>
                  <a
                    href={session.addToCalendar.ics}
                    className="btn btn-outlook"
                  >
                    Ajouter dans Outlook
                  </a>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4">
              <div className="card bg-light text-center">
                <div className="card-body">
                  <img
                    src="/build/images/addToCalendar/ical.png"
                    alt="Ajouter dans iCal"
                    width="100"
                  />
                  <h5 className="card-title">iCal</h5>
                  <a href={session.addToCalendar.ics} className="btn btn-ical">
                    Ajouter dans iCal
                  </a>
                </div>
              </div>
            </Col>
            <Col xs="12" md="4">
              <div className="card bg-light text-center">
                <div className="card-body">
                  <img
                    src="/build/images/addToCalendar/google-calendar.png"
                    alt="Ajouter dans Calendar"
                    width="100"
                  />
                  <h5 className="card-title">Google</h5>
                  <a
                    href={session.addToCalendar.googleCalendar}
                    target="_blank"
                    className="btn btn-google-calendar"
                  >
                    Ajouter dans Calendar
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeCallback}>
            Fermer
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

AddToCalendarModal.propTypes = {
  session: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  closeCallback: PropTypes.func.isRequired,
};

export default AddToCalendarModal;
