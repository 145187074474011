import { combineReducers } from "redux";
import { CommonReducer } from "../shared/data/common";
import { BasicMapReducer } from "../shared/data/basicMap";
import { initialState as BasicMapState } from "../shared/data/basicMap/BasicMapReducer";

export default combineReducers({
  basicMap: BasicMapReducer,
  common: CommonReducer,
});

export const initialStates = {
  basicMap: BasicMapState,
};
