import { put, takeLatest } from "redux-saga/effects";
import { FavoriteActionsTypes, FavoriteActions } from "./index";
import axios from "axios";

export function* favoritesFetchOneSaga({ className, entityId }) {
  const data = yield axios
    .get(`/api/favorite/${className}/${entityId}/exists`)
    .then((response) => response.data);

  if (data === "OK") {
    yield put(FavoriteActions.addedFavorite(className, entityId));
  }
}

export function* favoritesFetchSaga({ classNames }) {
  const data = yield axios
    .get("/api/favorite", {
      params: {
        classNames,
      },
    })
    .then((response) => response.data);

  if (data) {
    yield put(FavoriteActions.receivedFavorites(data));
  }
}

export function* favoritesAddSaga({ className, entityId }) {
  yield axios
    .post(`/api/favorite/${className}/${entityId}/add`)
    .then((response) => response);

  yield put(FavoriteActions.addedFavorite(className, entityId));
}

export function* favoritesRemoveSaga({ className, entityId }) {
  yield axios
    .post(`/api/favorite/${className}/${entityId}/remove`)
    .then((response) => response);

  yield put(FavoriteActions.removedFavorite(className, entityId));
}

export const favoriteSagas = [
  takeLatest(FavoriteActionsTypes.FAVORITES_FETCH_ONE, favoritesFetchOneSaga),
  takeLatest(FavoriteActionsTypes.FAVORITES_FETCH, favoritesFetchSaga),
  takeLatest(FavoriteActionsTypes.FAVORITE_ADD, favoritesAddSaga),
  takeLatest(FavoriteActionsTypes.FAVORITE_REMOVE, favoritesRemoveSaga),
];
