import * as SearchActionsTypes from "./SearchActionsTypes";

export const initSearchForm = () => ({
  type: SearchActionsTypes.SEARCH_INIT_FROM,
});

export const initSearch = () => ({
  type: SearchActionsTypes.SEARCH_INIT,
});

export const fetchSeances = (page = 0) => ({
  type: SearchActionsTypes.SEANCES_FETCH,
  page,
});

export const receivedSeances = (data, page) => ({
  type: SearchActionsTypes.SEANCES_RECEIVED,
  data,
  page,
});

export const cancelFetchSeances = () => ({
  type: SearchActionsTypes.SEANCES_FETCH_CANCEL,
});

export const fetchCurrentSession = (sessionId) => ({
  type: SearchActionsTypes.CURRENT_SESSION_FETCH,
  sessionId,
});

export const receivedCurrentSession = (data) => ({
  type: SearchActionsTypes.CURRENT_SESSION_RECEIVED,
  data,
});

export const errorCurrentSession = () => ({
  type: SearchActionsTypes.CURRENT_SESSION_ERROR,
});

export const toggleMobileMap = (value) => ({
  type: SearchActionsTypes.MOBILE_MAP_TOGGLE,
  value,
});

export const toggleFilterModal = (value) => ({
  type: SearchActionsTypes.FILTER_MODAL_TOGGLE,
  value,
});

export const setPlaceHovered = (placeId) => ({
  type: SearchActionsTypes.PLACE_HOVERED_SET,
  placeId,
});

export const resetPlaceHovered = () => ({
  type: SearchActionsTypes.PLACE_HOVERED_RESET,
});

export const setSearchParamSport = (sportId) => ({
  type: SearchActionsTypes.SEARCH_PARAM_SPORT_SET,
  sportId,
});

export const setSearchParamLaps = (organizationId) => ({
  type: SearchActionsTypes.SEARCH_PARAM_LAPS_SET,
  organizationId,
});

export const setSearchParamDate = (date) => ({
  type: SearchActionsTypes.SEARCH_PARAM_DATE_SET,
  date,
});

export const setSearchParamTime = (time) => ({
  type: SearchActionsTypes.SEARCH_PARAM_TIME_SET,
  time,
});

export const setSearchParamActivityTemplate = (templateId) => ({
  type: SearchActionsTypes.SEARCH_PARAM_ACTIVITY_TEMPLATE_SET,
  templateId,
});

export const setSearchParamOnlineOnly = (value) => ({
  type: SearchActionsTypes.SEARCH_PARAM_ONLINE_ONLY_SET,
  value,
});

export const setSearchParamAvailableOnly = (value) => ({
  type: SearchActionsTypes.SEARCH_PARAM_AVAILABLE_ONLY_SET,
  value,
});

export const setSearchParamHideForSubscriber = (value) => ({
  type: SearchActionsTypes.SEARCH_PARAM_HIDE_FOR_SUBSCRIBER_SET,
  value,
});

export const fetchAvailableSports = () => ({
  type: SearchActionsTypes.AVAILABLE_SPORTS_FETCH,
});

export const receivedAvailableSports = (data) => ({
  type: SearchActionsTypes.AVAILABLE_SPORTS_RECEIVED,
  data,
});
