export const autocompleteIsLoading = (state) => state.autocomplete.isLoading;
export const getAutocompleteValue = (state) => state.autocomplete.value;
export const getAutocompleteResult = (state) => state.autocomplete.result;
export const getAutocompleteHasNoResult = (state) =>
  state.autocomplete.noResult;
export const locationIsAlreadyFetch = (state) =>
  state.autocomplete.locationAlreadyFetch;
export const getLocationError = (state) => state.autocomplete.locationError;
export const getLocationIsLoading = (state) =>
  state.autocomplete.locationIsLoading;
export const getLocation = (state) =>
  state.autocomplete?.location ?? { lat: null, lng: null };
export const shouldReverseGeocode = (state) =>
  state.autocomplete.shouldReverseGeocode;
export const getLastSelectedPlaces = (state) =>
  state.autocomplete.lastSelectedPlaces;
