import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import merge from "deepmerge";

import rootReducer, { initialStates } from "./rootReducer";
import rootSaga from "./rootSaga";
import * as Sentry from "@sentry/react";

// if (process.env.NODE_ENV === "production") {
//   Sentry.init({
//     dsn: "https://751b0e117f9244b584b65b105d65bca8@o286829.ingest.sentry.io/1520819",
//   });
// }

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

export default function configureStore(props) {
  // This is how we get initial props from Symfony into redux.
  const { basicMap } = props;

  // Redux expects to initialize the store using an Object
  const initialState = merge.all([initialStates, { basicMap }]);

  // use devtools if we are in a browser and the extension is enabled
  const composeEnhancers =
    (typeof window !== "undefined" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const sagaMiddleware = createSagaMiddleware();

  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware), sentryReduxEnhancer)
  );

  sagaMiddleware.run(rootSaga);

  return store;
}
