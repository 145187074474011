import * as FavoriteActionsTypes from "./FavoriteActionsTypes";

export const fetchOneFavorite = (className, entityId) => ({
  type: FavoriteActionsTypes.FAVORITES_FETCH_ONE,
  className,
  entityId,
});

export const fetchFavorites = (classNames) => ({
  type: FavoriteActionsTypes.FAVORITES_FETCH,
  classNames,
});

export const receivedFavorites = (data) => ({
  type: FavoriteActionsTypes.FAVORITES_RECEIVED,
  data,
});

export const addFavorite = (className, entityId) => ({
  type: FavoriteActionsTypes.FAVORITE_ADD,
  className,
  entityId,
});

export const addedFavorite = (className, entityId) => ({
  type: FavoriteActionsTypes.FAVORITE_ADDED,
  className,
  entityId,
});

export const removeFavorite = (className, entityId) => ({
  type: FavoriteActionsTypes.FAVORITE_REMOVE,
  className,
  entityId,
});

export const removedFavorite = (className, entityId) => ({
  type: FavoriteActionsTypes.FAVORITE_REMOVED,
  className,
  entityId,
});
