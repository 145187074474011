import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import RootContainer from "./containers/RootContainer";
import Moment from "moment";
import momentLocalizer from "react-widgets-moment";

import "react-widgets/dist/css/react-widgets.css";

Moment.locale("fr");
momentLocalizer();

// See documentation for https://github.com/reactjs/react-redux.
// This code here binds your smart component to the redux store.
const AppBasicMap = () => {
  const store = ReactOnRails.getStore("basicMapStore");

  return (
    <Provider store={store}>
      <RootContainer />
    </Provider>
  );
};

export default AppBasicMap;
