import * as AutocompleteActionsTypes from "./AutocompleteActionsTypes";

export const fetchAutocompletes = (search) => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_FETCH,
  search,
});

export const receivedAutocompletes = (data) => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_RECEIVED,
  data,
});

export const autocompletesError = () => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_ERROR,
});

export const unselectPlace = () => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_UNSELECT,
});

export const selectPlace = (place) => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_SELECT,
  place,
});

export const autocompleteReset = () => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_RESET,
});

export const placeSelected = () => ({
  type: AutocompleteActionsTypes.AUTOCOMPLETE_SELECTED,
});

export const fetchLocation = () => ({
  type: AutocompleteActionsTypes.LOCATION_FETCH,
});

export const locationReceived = (data) => ({
  type: AutocompleteActionsTypes.LOCATION_RECEIVED,
  data,
});

export const locationError = (error) => ({
  type: AutocompleteActionsTypes.LOCATION_ERROR,
  error,
});

export const editCoordinates = (
  coordinates,
  shouldReverseGeocoding = false
) => ({
  type: AutocompleteActionsTypes.LOCATION_EDIT,
  coordinates,
  shouldReverseGeocoding,
});

export const fetchReverseGeocoding = () => ({
  type: AutocompleteActionsTypes.REVERSE_GEOCODE_FETCH,
});

export const reverseGeocodingReceived = (data, latitude, longitude) => ({
  type: AutocompleteActionsTypes.REVERSE_GEOCODE_RECEIVED,
  data,
  latitude,
  longitude,
});

export const reverseGeocodingError = () => ({
  type: AutocompleteActionsTypes.REVERSE_GEOCODE_ERROR,
});

export const addLastSelectedPlaces = (place) => ({
  type: AutocompleteActionsTypes.LAST_SELECTED_PLACES_ADD,
  place,
});

export const retrieveLastSelectedPlaces = () => ({
  type: AutocompleteActionsTypes.LAST_SELECTED_PLACES_RETRIEVE,
});

export const lastSelectedPlacesRetrieved = (places) => ({
  type: AutocompleteActionsTypes.LAST_SELECTED_PLACES_RETRIEVED,
  places,
});

export const lastSelectedPlacesAutofill = () => ({
  type: AutocompleteActionsTypes.LAST_SELECTED_PLACES_AUTOFILL,
});
