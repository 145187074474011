import { takeEvery, takeLatest, put, call } from "redux-saga/effects";
import { startSubmit, stopSubmit, reset } from "redux-form";
import * as CommonActionsTypes from "./CommonActionsTypes";
import { formHelper } from "../../helpers";
import { registrationFormSuccess } from "./CommonActions";
import { userApi } from "../../api";

function* gtmEventSendSaga({ event, additionalParams }) {
  if (typeof dataLayer !== "undefined") {
    dataLayer.push({ event, ...additionalParams });
  } else {
    console.log("Debug GTM", { event, ...additionalParams });
  }
}

function* registrationFormSubmitSaga({ data, targetPath }) {
  const formId = "registration";
  yield put(startSubmit(formId));

  try {
    const res = yield call(userApi.postRegistrationForm, data);
    if (res.code && res.code === 400) {
      yield put(
        stopSubmit(formId, formHelper.formatSymfonyFormErrorResponse(res))
      );
    } else {
      yield put(reset(formId));
      yield put(stopSubmit(formId));
      yield put(registrationFormSuccess(targetPath));
    }
  } catch (error) {
    yield put(
      stopSubmit(formId, formHelper.formatSymfonyServerErrorResponse(error))
    );
  }
}

function* registrationFormSuccessSaga({ targetPath }) {
  window.location.href = targetPath;
}

export const commonSagas = [
  takeEvery(CommonActionsTypes.GTM_EVENT_SEND, gtmEventSendSaga),
  takeLatest(
    CommonActionsTypes.REGISTRATION_FORM_SUBMIT,
    registrationFormSubmitSaga
  ),
  takeLatest(
    CommonActionsTypes.REGISTRATION_FORM_SUCCESS,
    registrationFormSuccessSaga
  ),
];
