import React, { Component, useMemo } from "react";
import connect from "react-redux/es/connect/connect";
import { BrowserRouter as Router, Route } from "react-router-dom";
import PropTypes from "prop-types";
import SearchContainer from "./SearchContainer";
import SessionContainer from "./SessionContainer";
import BookingModalContainer from "./BookingModalContainer";
import { getUrlPrefix } from "../../shared/data/common/CommonSelectors";
import { CommonSelectors } from "../../shared/data/common";
import { FavoriteActions } from "../../shared/data/favorite";
import { Helmet } from "react-helmet";
import FilterModalContainer from "./FilterModalContainer";
import WaitingListModalContainer from "./WaitingListModalContainer";
import axios from "axios";

const SessionContainerWrapper = (props) => {
  useMemo(() => {
    axios.post(
      `/search/api/session/${props.match.params.id}/interaction/sessionView`
    );
  }, [props.match.params.id]);

  return <SessionContainer {...props} />;
};

class RootContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false, //Set render state to false
    };
  }

  componentDidMount() {
    const { userIsConnected } = this.props;

    setTimeout(
      function () {
        //Start the timer
        this.setState({ render: true }); //After 1 second, set render to true
      }.bind(this),
      1000
    );
  }

  render() {
    if (!this.state.render) {
      return <div />;
    }

    const { urlPrefix } = this.props;

    return (
      <Router>
        <Helmet
          onChangeClientState={(a, b, c) => {
            if (
              typeof addthis != "undefined" &&
              typeof addthis.layers != "undefined" &&
              typeof addthis.layers.refresh != "undefined"
            ) {
              addthis.layers.refresh();
            }
          }}
        >
          <title>Recherche</title>
          <meta property="og:title" content="Recherche" />
          <meta
            property="og:image"
            itemProp="image"
            content="https://www.mss.com/build/images/android-chrome-600x600.png"
          />
          <meta
            property="og:image:secure_url"
            itemProp="image"
            content="https://www.mss.com/build/images/android-chrome-600x600.png"
          />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="600" />
          <meta
            property="og:url"
            content={`${window.location.protocol}://${window.location.hostname}/${urlPrefix}`}
          />
          <link
            rel="canonical"
            href={`${window.location.protocol}://${window.location.hostname}/${urlPrefix}`}
          />
        </Helmet>
        <Route exact path={urlPrefix || "/"} component={SearchContainer} />
        <Route
          path={`${urlPrefix || ""}/session/:id`}
          component={SessionContainerWrapper}
        />
        <BookingModalContainer />
        <WaitingListModalContainer />
        <FilterModalContainer />
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  urlPrefix: getUrlPrefix(state),
  userIsConnected: CommonSelectors.getUserIsConnected(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchFavorites: (classNames) =>
    dispatch(FavoriteActions.fetchFavorites(classNames)),
});

RootContainer.propTypes = {
  urlPrefix: PropTypes.string,
  fetchFavorites: PropTypes.func.isRequired,
  userIsConnected: PropTypes.bool.isRequired,
};

RootContainer.defaultProps = {
  urlPrefix: null,
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
