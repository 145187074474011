"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionHelper = void 0;
const getBackgroundColor = (session, isBooked = false) => {
    if (isBooked) {
        return "#28a745";
    }
    if (session.withoutBooking) {
        return "#3a70ff";
    }
    if (session.hasUnlimitedSeats) {
        return "#3a70ff";
    }
    if (session.remainingSeats !== null && session.remainingSeats < 1) {
        if (session.hasWaitingList) {
            return "#FFC100";
        }
        return "#FF8A8A";
    }
    return "#3a70ff";
};
exports.SessionHelper = {
    getBackgroundColor,
};
