import axios from "axios";

require("bootstrap");
require("jquery");
require("popper.js");

import lozad from "lozad";
import bsCustomFileInput from "bs-custom-file-input";
import { computeDistance } from "../shared/helpers/location";
window.computeDistance = computeDistance;

const $ = require("jquery");
global.$ = global.jQuery = $;

$(function () {
  $('[data-toggle="tooltip"]').tooltip();
});

$(document).ready(function () {
  bsCustomFileInput.init();

  const observer = lozad();
  observer.observe();
});

class InteractionLogger {
  logOrganizationInteraction(organizationId, type) {
    axios.post(
      `/search/api/organization/${organizationId}/interaction/${type}`
    );
  }
}

window.InteractionLogger = new InteractionLogger();
