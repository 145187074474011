import React, { Component } from "react";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import PropTypes from "prop-types";
import { Form, Alert, Button } from "reactstrap";
import TextInput from "./elements/TextInput";
import CheckboxInput from "./elements/CheckboxInput";
import ReCaptcha from "./elements/ReCaptcha";

const messages = {
  email: "Adresse e-mail",
  plainPasswordFirst: "Mot de passe",
  plainPasswordSecond: "Répéter le mot de passe",
  submit: "Créer un compte & Réserver",
  submitWaitingList: "Créer un compte & Rejoindre",
  referrer: "Code de parrainage",
  referrer_help:
    "Si vous avez reçu un code de parrainage, saisissez-le ici, sinon laissez la case vide.",
};

class RegistrationForm extends Component {
  render() {
    const { error, handleSubmit, submitting, waitingList } = this.props;

    return (
      <Form onSubmit={handleSubmit}>
        <Field
          label={messages.email}
          name="email"
          component={TextInput}
          type="email"
        />
        <Field
          label={messages.plainPasswordFirst}
          name="plainPassword.first"
          component={TextInput}
          type="password"
        />
        <Field
          label={messages.plainPasswordSecond}
          name="plainPassword.second"
          component={TextInput}
          type="password"
        />
        <Field
          label={
            <React.Fragment>
              En m'inscrivant, j'accepte les{" "}
              <a href="/cgu" className="underline" target="_blank">
                conditions générales d'utilisation
              </a>{" "}
              et la{" "}
              <a
                href="/confidentialite-des-donnees"
                className="underline"
                target="_blank"
              >
                politique de confidentialité
              </a>
              .
            </React.Fragment>
          }
          name="cgv"
          component={CheckboxInput}
          type="checkbox"
        />
        <Field name="recaptcha" component={ReCaptcha} props={{ submitting }} />
        <Field
          label={
            <React.Fragment>
              {messages.referrer}
              <i
                className="far fa-question-circle text-success ml-2"
                data-toggle="tooltip"
                data-placement="top"
                title=""
                data-original-title={messages.referrer_help}
              />
            </React.Fragment>
          }
          name="referrer"
          component={TextInput}
          type="text"
        />
        {error && <Alert color="danger">{error}</Alert>}
        <Button color="primary" disabled={submitting} block>
          {!waitingList && messages.submit}
          {waitingList && messages.submitWaitingList}
        </Button>
      </Form>
    );
  }
}

RegistrationForm.propTypes = {
  error: PropTypes.string,
  initialValues: PropTypes.shape({
    email: PropTypes.string,
    plainPassword_first: PropTypes.string,
    plainPassword_second: PropTypes.string,
    cgv: PropTypes.bool,
  }),
  waitingList: PropTypes.bool.isRequired,
  submitting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

RegistrationForm.defaultProps = {
  error: null,
  initialValues: null,
  waitingList: false,
};

export default connect()(reduxForm({ form: "registration" })(RegistrationForm));
