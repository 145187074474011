import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { SearchReducer } from "../shared/data/search";
import { BookingReducer } from "../shared/data/booking";
import { CommonReducer } from "../shared/data/common";
import { AutocompleteReducer } from "../shared/data/autocomplete";
import { initialState as SearchState } from "../shared/data/search/SearchReducer";
import { initialState as BookingState } from "../shared/data/booking/BookingReducer";
import { initialState as CommonState } from "../shared/data/common/CommonReducer";
import { initialState as AutocompleteState } from "../shared/data/autocomplete/AutocompleteReducer";

export default combineReducers({
  common: CommonReducer,
  search: SearchReducer,
  booking: BookingReducer,
  autocomplete: AutocompleteReducer,
  form: formReducer,
});

export const initialStates = {
  common: CommonState,
  search: SearchState,
  booking: BookingState,
  autocomplete: AutocompleteState,
};
