import React from "react";

const CancellationPolicy = () => (
  <div className="cancellation-policy">
    <p>
      Conditions d'annulation :
      <br />
      Si vous ne pouvez plus assister à cette séance, vous pourrez annuler à
      tout moment votre réservation via l’e-mail de confirmation que vous aurez
      reçu, ou en cliquant sur annuler sur votre séance sur l’espace Mes
      Réservations de votre compte.
    </p>
  </div>
);

export default CancellationPolicy;
