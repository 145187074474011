import * as CommonActionsTypes from "./CommonActionsTypes";

export const gtmEventSend = (event, additionalParams = {}) => ({
  type: CommonActionsTypes.GTM_EVENT_SEND,
  event,
  additionalParams,
});

export const registrationFormSubmit = (data, targetPath) => ({
  type: CommonActionsTypes.REGISTRATION_FORM_SUBMIT,
  data,
  targetPath,
});

export const registrationFormSuccess = (targetPath) => ({
  type: CommonActionsTypes.REGISTRATION_FORM_SUCCESS,
  targetPath,
});
