import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { BookingActions, BookingSelectors } from "../../shared/data/booking";
import CancellationPolicy from "../components/CancellationPolicy";
import { CommonActions, CommonSelectors } from "../../shared/data/common";
import RegistrationForm from "../../shared/components/forms/RegistrationForm";
import NumberOfUnitsSelect from "../components/NumberOfUnitsSelect";
import { getUrlPrefix } from "../../shared/data/common/CommonSelectors";
import axios from "axios";

const messages = {
  register: "S'inscrire",
  login: "J'ai déjà un compte",
  withFacebook: "Continuer avec Facebook",
  withGoogle: "Continuer avec Google",
};

class ToBookInformationModalContainer extends Component {
  render() {
    const {
      session,
      toBookInformationModalIsOpen,
      closeToBookInformationModal,
      isConnected,
      registrationFormSubmit,
      urlPrefix,
      isInIframe,
    } = this.props;

    if (!session) {
      return null;
    }

    if (!session.bookingWithoutAccount && !isConnected) {
      return (
        <div>
          <Modal
            className="booking-modal"
            isOpen={toBookInformationModalIsOpen}
            toggle={closeToBookInformationModal}
          >
            <ModalHeader toggle={closeToBookInformationModal}>
              Information concernant la réservation
            </ModalHeader>
            <ModalBody>
              <RegistrationForm
                onSubmit={(data) =>
                  registrationFormSubmit(
                    data,
                    `/book/${session.id}?openToBookInformationModal=1`
                  )
                }
              />
              <hr className="m-t-20 m-b-20" />
              <Button
                color="outline-primary"
                block
                href={`/login?_target_path=${urlPrefix || ""}/session/${
                  session.id
                }?openToBookInformationModal=1`}
                target={isInIframe ? "_blank" : "_self"}
              >
                {messages.login}
              </Button>
              <hr className="m-t-20 m-b-20" />
              <a
                className="btn btn-block btn-outline-facebook"
                href={`/connect/facebook?_target_path=${
                  urlPrefix || ""
                }/session/${session.id}?openToBookInformationModal=1`}
                target={isInIframe ? "_blank" : "_self"}
              >
                <i className="fab fa-facebook" />
                {messages.withFacebook}
              </a>
              <a
                className="btn btn-block btn-outline-google"
                href={`/connect/google?_target_path=${
                  urlPrefix || ""
                }/session/${session.id}?openToBookInformationModal=1`}
                target={isInIframe ? "_blank" : "_self"}
              >
                <i className="fab fa-google" />
                {messages.withGoogle}
              </a>
            </ModalBody>
          </Modal>
        </div>
      );
    }

    if (session.bookingLink) {
      const win = window.open();
      setTimeout(() => {
        axios.post(
          `/search/api/session/${session.id}/interaction/withoutBookingSessionRedirect`
        );
        win.location = session.bookingLink;
        win.focus();
      }, 3000);
    }

    return (
      <div>
        <Modal
          className="booking-modal"
          isOpen={toBookInformationModalIsOpen}
          toggle={closeToBookInformationModal}
        >
          <ModalHeader toggle={closeToBookInformationModal}>
            Information concernant la réservation
          </ModalHeader>
          <ModalBody>
            <div className="pt-4 pb-4 text-center">
              {session.bookingLink && (
                <div>
                  <p>
                    Vous allez être redirigé(e) sur le site{" "}
                    {session.organizationCategory.gender == 1 ? "du" : "de la"}{" "}
                    {session.organizationCategory.name} "{session.laps}" pour
                    procéder à votre réservation
                  </p>
                  <a
                    href={session.bookingLink}
                    className="btn btn-info"
                    onClick={() =>
                      axios.post(
                        `/search/api/session/${session.id}/interaction/withoutBookingSessionRedirect`
                      )
                    }
                    target="_blank"
                  >
                    Accéder au site
                  </a>
                </div>
              )}
              {!session.bookingLink && session.bookingPhoneNumber && (
                <div>
                  <p>
                    Pour confirmer votre réservation, veuillez appeler{" "}
                    {session.organizationCategory.gender == 1 ? "le" : "la"}{" "}
                    {session.organizationCategory.name} "{session.laps}" au
                    numéro suivant
                  </p>
                  <a
                    href={`tel://${session.bookingPhoneNumber.raw}`}
                    className="btn btn-info"
                    onClick={() =>
                      axios.post(
                        `/search/api/session/${session.id}/interaction/withoutBookingSessionPhoneNumberClick`
                      )
                    }
                    target="_blank"
                  >
                    {session.bookingPhoneNumber.formatted}
                  </a>
                </div>
              )}
              {!session.bookingLink && !session.bookingPhoneNumber && (
                <p>
                  Rendez-vous directement sur place aux horaires d’ouverture
                  sans réservation.
                  <br />
                  Adresse :<br />
                  {session.place.name}, {session.place.address}
                </p>
              )}
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  toBookInformationModalIsOpen:
    BookingSelectors.toBookInformationModalIsOpen(state),
  session: BookingSelectors.getSession(state),
  isConnected: CommonSelectors.getUserIsConnected(state),
  isInIframe: CommonSelectors.getInIframe(state),
  urlPrefix: getUrlPrefix(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeToBookInformationModal: () =>
    dispatch(BookingActions.closeToBookInformationModal()),
  registrationFormSubmit: (data, targetPath) =>
    dispatch(CommonActions.registrationFormSubmit(data, targetPath)),
});

ToBookInformationModalContainer.propTypes = {
  toBookInformationModalIsOpen: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  isInIframe: PropTypes.bool.isRequired,
  session: PropTypes.object,
  closeToBookInformationModal: PropTypes.func.isRequired,
  registrationFormSubmit: PropTypes.func.isRequired,
  urlPrefix: PropTypes.string,
};

ToBookInformationModalContainer.defaultProps = {
  session: null,
  urlPrefix: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ToBookInformationModalContainer);
