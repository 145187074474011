import PropTypes from "prop-types";
import { Map } from "react-leaflet";

class CustomLeafletMap extends Map {
  constructor(props) {
    super(props);

    this.state = {
      showMobileMap: props.showMobileMap,
    };
  }

  componentDidMount() {
    super.componentDidMount();
    window.addEventListener("resize", () => this.resize());
    this.resize();
  }

  componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener("resize", () => this.resize());
  }

  resize() {
    if (typeof Sentry != "undefined") {
      Sentry.addBreadcrumb({
        category: "leaflet",
        message:
          "Resize " +
          JSON.stringify(this.props.bounds) +
          " //// " +
          JSON.stringify(this.props.boundsOptions),
        level: Sentry.Severity.Debug,
      });
    }
    // console.log(JSON.stringify(this.props.bounds))
    // console.log(JSON.stringify(this.props.boundsOptions))
    // https://github.com/Leaflet/Leaflet/issues/7508
    if (this.props.bounds && this.container && this.container._leaflet_id) {
      this.leafletElement.fitBounds(
        this.props.bounds,
        this.getFitBoundsOptions(this.props)
      );
    }
  }

  getPosition(el) {
    // this method is only used for elements previously positioned using setPosition,
    // so it's safe to cache the position for performance
    if (el) {
      return el._leaflet_pos || new Point(0, 0);
    } else {
      return new Point(0, 0);
    }
  }

  updateLeafletElement(fromProps, toProps) {
    if (fromProps.showMobileMap !== toProps.showMobileMap) {
      this.leafletElement.invalidateSize();
    }

    super.updateLeafletElement(fromProps, toProps);

    if (fromProps.showMobileMap !== toProps.showMobileMap) {
      this.resize();
    }
  }
}

CustomLeafletMap.propTypes = {
  showMobileMap: PropTypes.bool,
};

export default CustomLeafletMap;
