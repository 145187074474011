export const SEARCH_INIT_FROM = "SEARCH_INIT_FORM";
export const SEARCH_INIT = "SEARCH_INIT";
export const SEANCES_FETCH = "SEANCES_FETCH";
export const SEANCES_RECEIVED = "SEANCES_RECEIVED";
export const SEANCES_FETCH_CANCEL = "SEANCES_FETCH_CANCEL";
export const CURRENT_SESSION_FETCH = "CURRENT_SESSION_FETCH";
export const CURRENT_SESSION_RECEIVED = "CURRENT_SESSION_RECEIVED";
export const CURRENT_SESSION_ERROR = "CURRENT_SESSION_ERROR";
export const MOBILE_MAP_TOGGLE = "MOBILE_MAP_TOGGLE";
export const FILTER_MODAL_TOGGLE = "FILTER_MODAL_TOGGLE";
export const PLACE_HOVERED_SET = "PLACE_HOVERED_SET";
export const PLACE_HOVERED_RESET = "PLACE_HOVERED_RESET";
export const SEARCH_PARAM_SPORT_SET = "SEARCH_PARAM_SPORT_SET";
export const SEARCH_PARAM_LAPS_SET = "SEARCH_PARAM_LAPS_SET";
export const SEARCH_PARAM_DATE_SET = "SEARCH_PARAM_DATE_SET";
export const SEARCH_PARAM_TIME_SET = "SEARCH_PARAM_TIME_SET";
export const SEARCH_PARAM_ACTIVITY_TEMPLATE_SET =
  "SEARCH_PARAM_ACTIVITY_TEMPLATE_SET";
export const SEARCH_PARAM_ONLINE_ONLY_SET = "SEARCH_PARAM_ONLINE_ONLY_SET";
export const SEARCH_PARAM_AVAILABLE_ONLY_SET =
  "SEARCH_PARAM_AVAILABLE_ONLY_SET";
export const SEARCH_PARAM_HIDE_FOR_SUBSCRIBER_SET = "SEARCH_PARAM_HIDE_FOR_SUBSCRIBER_SET";
export const AVAILABLE_SPORTS_FETCH = "AVAILABLE_SPORTS_FETCH";
export const AVAILABLE_SPORTS_RECEIVED = "AVAILABLE_SPORTS_RECEIVED";
