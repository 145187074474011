import React from "react";
import ReactOnRails from "react-on-rails";
import { Provider } from "react-redux";
import RootContainer from "./containers/RootContainer";
import moment from "moment-timezone";
import momentLocalizer from "react-widgets-moment";
import { QueryClient, QueryClientProvider } from "react-query";
import "react-widgets/dist/css/react-widgets.css";
import { ReactQueryDevtools } from "react-query/devtools";
import SearchDataLoader from "./data-loader/SearchDataLoader";

moment.locale("fr");
moment.tz.setDefault("Europe/Paris");
momentLocalizer();

const queryClient = new QueryClient();

// See documentation for https://github.com/reactjs/react-redux.
// This code here binds your smart component to the redux store.
const AppSearch = () => {
  const store = ReactOnRails.getStore("searchStore");

  return (
    <SearchDataLoader>
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
          <RootContainer />
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </Provider>
    </SearchDataLoader>
  );
};

export default AppSearch;
