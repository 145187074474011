import React, { Component } from "react";
import PropTypes from "prop-types";
import connect from "react-redux/es/connect/connect";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { BookingActions, BookingSelectors } from "../../shared/data/booking";
import CancellationPolicy from "../components/CancellationPolicy";
import { CommonActions, CommonSelectors } from "../../shared/data/common";
import RegistrationForm from "../../shared/components/forms/RegistrationForm";
import NumberOfUnitsSelect from "../components/NumberOfUnitsSelect";

const messages = {
  submit: "Je confirme",
  submitWithoutBooking: "J'y vais",
  register: "S'inscrire",
  login: "J'ai déjà un compte",
  withFacebook: "Continuer avec Facebook",
  withGoogle: "Continuer avec Google",
};

class WaitingListModalContainer extends Component {
  render() {
    const {
      session,
      waitingListModalIsOpen,
      closeWaitingListModal,
      isConnected,
      registrationFormSubmit,
      isInIframe,
    } = this.props;

    if (!session) {
      return null;
    }

    return (
      <div>
        <Modal
          className="booking-modal"
          isOpen={waitingListModalIsOpen}
          toggle={closeWaitingListModal}
        >
          <ModalHeader toggle={closeWaitingListModal}>
            Liste d'attente
          </ModalHeader>
          <ModalBody>
            <div>
              <p className="wording-line1">Je rejoins la liste d'attente</p>
              <p className="wording-line3">
                En m’inscrivant sur la liste d’attente, en cas de désistement,
                je recevrai un email pour m’informer qu’une place s’est libérée
                avec la possibilité de m’inscrire.
              </p>
              <p className="wording-line3">Premier arrivé, premier servi.</p>
            </div>
            {isConnected && (
              <React.Fragment>
                <hr className="m-t-20 m-b-20" />
                <Button
                  color="outline-primary"
                  block
                  href={`/waitingList/${session.id}`}
                >
                  Rejoindre la liste d'attente
                </Button>
              </React.Fragment>
            )}
            {!isConnected && (
              <React.Fragment>
                <RegistrationForm
                  onSubmit={(data) =>
                    registrationFormSubmit(data, `/waitingList/${session.id}`)
                  }
                  waitingList={true}
                />
                <hr className="m-t-20 m-b-20" />
                <Button
                  color="outline-primary"
                  block
                  href={`/login?_target_path=/waitingList/${session.id}`}
                  target={isInIframe ? "_blank" : "_self"}
                >
                  {messages.login}
                </Button>
                <hr className="m-t-20 m-b-20" />
                <a
                  className="btn btn-block btn-outline-facebook"
                  href={`/connect/facebook?_target_path=/waitingList/${session.id}`}
                  target={isInIframe ? "_blank" : "_self"}
                >
                  <i className="fab fa-facebook" />
                  {messages.withFacebook}
                </a>
                <a
                  className="btn btn-block btn-outline-google"
                  href={`/connect/google?_target_path=/waitingList/${session.id}`}
                  target={isInIframe ? "_blank" : "_self"}
                >
                  <i className="fab fa-google" />
                  {messages.withGoogle}
                </a>
              </React.Fragment>
            )}
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  waitingListModalIsOpen: BookingSelectors.waitingListModalIsOpen(state),
  session: BookingSelectors.getSession(state),
  isConnected: CommonSelectors.getUserIsConnected(state),
  isInIframe: CommonSelectors.getInIframe(state),
});

const mapDispatchToProps = (dispatch) => ({
  closeWaitingListModal: () => dispatch(BookingActions.closeWaitingListModal()),
  registrationFormSubmit: (data, targetPath) =>
    dispatch(CommonActions.registrationFormSubmit(data, targetPath)),
});

WaitingListModalContainer.propTypes = {
  waitingListModalIsOpen: PropTypes.bool.isRequired,
  isConnected: PropTypes.bool.isRequired,
  session: PropTypes.object,
  closeWaitingListModal: PropTypes.func.isRequired,
  registrationFormSubmit: PropTypes.func.isRequired,
  isInIframe: PropTypes.bool.isRequired,
};

WaitingListModalContainer.defaultProps = {
  session: null,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WaitingListModalContainer);
