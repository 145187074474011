import React, { Component } from "react";
import { Spinner } from "reactstrap";

class Loader extends Component {
  render() {
    return (
      <div className="loader">
        <div>
          <Spinner />
          <br />
          Chargement en cours...
        </div>
      </div>
    );
  }
}

export default Loader;
