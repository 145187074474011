import { combineReducers } from "redux";
import { FavoriteReducer } from "../shared/data/favorite";
import { CommonReducer } from "../shared/data/common";
import { initialState as FavoriteState } from "../shared/data/favorite/FavoriteReducer";
import { initialState as CommonState } from "../shared/data/common/CommonReducer";

export default combineReducers({
  favorite: FavoriteReducer,
  common: CommonReducer,
});

export const initialStates = {
  favorite: FavoriteState,
  common: CommonState,
};
