"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = __importStar(require("react"));
const SearchContext_1 = require("./SearchContext");
// @ts-ignore
const history_1 = require("history");
const Api_1 = require("../services/Api");
function SearchDataLoader({ children, editUrlParams = true, }) {
    const [selectedTemplate, setSelectedTemplate] = (0, react_1.useState)(null);
    const [showMobileMap, setShowMobileMap] = (0, react_1.useState)(false);
    const [isInited, setIsInited] = (0, react_1.useState)(false);
    const [urlPrefix, setUrlPrefix] = (0, react_1.useState)(null);
    const [specificOrganizationId, setSpecificOrganizationId] = (0, react_1.useState)(null);
    const [whiteLabel, setWhiteLabel] = (0, react_1.useState)(null);
    const [hoveredPlaceIdOnGrid, setHoveredPlaceIdOnGrid] = (0, react_1.useState)(null);
    const [hoveredPlaceIdOnMap, setHoveredPlaceIdOnMap] = (0, react_1.useState)(null);
    const defaultSearchParams = (0, react_1.useMemo)(() => ({
        date: null,
        sport: null,
        organizationId: null,
        partnerId: null,
        isWhiteLabel: null,
        activity: null,
        placeId: null,
        address: null,
        lat: null,
        lng: null,
    }), []);
    const [searchParams, _setSearchParams] = (0, react_1.useState)(defaultSearchParams);
    const setSearchParams = (sp) => {
        // Hack to prevent undefined values
        _setSearchParams(Object.assign(Object.assign({}, searchParams), sp));
    };
    // useWhatChanged([
    //   searchParams.sport,
    //   searchParams.organizationId,
    //   selectedTemplate,
    // ]);
    const editSearchParam = ({ type, value }) => {
        setSearchParams(Object.assign(Object.assign({}, searchParams), { [type]: value !== null && value !== void 0 ? value : null }));
    };
    const retrieveAddressFromLocalStorage = (0, react_1.useCallback)((sp) => {
        if ((sp === null || sp === void 0 ? void 0 : sp.address) == null &&
            (sp === null || sp === void 0 ? void 0 : sp.organizationId) == null &&
            (sp === null || sp === void 0 ? void 0 : sp.partnerId) == null) {
            const searchAddress = window.localStorage.getItem("searchAddress");
            if (searchAddress) {
                return JSON.parse(searchAddress);
            }
        }
        return {};
    }, []);
    const history = (0, react_1.useMemo)(() => (0, history_1.createBrowserHistory)(), []);
    (0, react_1.useMemo)(() => {
        // setTimeout(() => {
        if (isInited) {
            return;
        }
        // Retrieve json in #search-init-data
        const searchInitData = document.getElementById("search-init-data");
        let initialData = [];
        if (searchInitData) {
            initialData = JSON.parse(searchInitData.innerHTML);
        }
        if (initialData["urlPrefix"]) {
            setUrlPrefix(initialData["urlPrefix"]);
        }
        if (initialData["specificOrganizationId"]) {
            setSpecificOrganizationId(initialData["specificOrganizationId"]);
        }
        let newSearchParams = Object.assign(Object.assign({}, searchParams), retrieveAddressFromLocalStorage(searchParams));
        if (initialData["partnerId"]) {
            newSearchParams["partnerId"] = initialData["partnerId"];
        }
        const urlSearchParams = new URLSearchParams(window.location.search);
        for (const [key, value] of urlSearchParams.entries()) {
            let castValue;
            if (key === "organizationId" || key === "activity") {
                castValue = parseInt(value, 10);
            }
            if (key === "templateKey") {
                if (!value.endsWith("XXXX")) {
                    Api_1.Api.getTemplateFromKey(value)
                        .get()
                        .then((template) => {
                        setSelectedTemplate(template);
                    });
                }
            }
            // @ts-ignore
            newSearchParams[key] = castValue !== null && castValue !== void 0 ? castValue : value;
        }
        // @ts-ignore
        if (newSearchParams["openMap"] === "1") {
            setShowMobileMap(true);
        }
        if (newSearchParams.organizationId ||
            newSearchParams.partnerId ||
            newSearchParams.placeId) {
            newSearchParams = Object.assign(Object.assign({}, newSearchParams), { address: null, lat: null, lng: null });
        }
        setSearchParams(newSearchParams);
        setIsInited(true);
        if (document.body.dataset.whiteLabel) {
            setWhiteLabel(document.body.dataset.whiteLabel);
        }
        // }, 1000);
    }, []);
    (0, react_1.useEffect)(() => {
        if (!isInited) {
            return;
        }
        if (editUrlParams &&
            window.location.pathname.split("/").findIndex((e) => e === "session") ===
                -1) {
            const params = [];
            if (selectedTemplate) {
                params.push(`templateKey=${selectedTemplate.key}`);
            }
            for (const [key, value] of Object.entries(searchParams)) {
                if (value) {
                    params.push(`${key}=${value}`);
                }
            }
            history.replace(`${urlPrefix}?${params.join("&")}`);
        }
        const { address, lat, lng } = searchParams;
        if (address && lat && lng) {
            window.localStorage.setItem("searchAddress", JSON.stringify({ address, lat, lng }));
        }
    }, [searchParams, selectedTemplate]);
    // useEffect(() => {
    //   if (!isInited) {
    //     return;
    //   }
    //
    //   setSelectedTemplate(null);
    // }, [searchParams?.organizationId, searchParams?.sport]);
    (0, react_1.useEffect)(() => {
        if (!isInited) {
            return;
        }
        if (!(searchParams === null || searchParams === void 0 ? void 0 : searchParams.organizationId) && !(searchParams === null || searchParams === void 0 ? void 0 : searchParams.placeId)) {
            setSearchParams(Object.assign(Object.assign({}, searchParams), retrieveAddressFromLocalStorage(searchParams)));
        }
        else {
            setSearchParams(Object.assign(Object.assign({}, searchParams), { address: null, lat: null, lng: null }));
        }
    }, [searchParams === null || searchParams === void 0 ? void 0 : searchParams.organizationId]);
    return (react_1.default.createElement(SearchContext_1.SearchContext.Provider, { value: {
            selectedTemplate,
            setSelectedTemplate,
            showMobileMap,
            setShowMobileMap,
            searchParams,
            editSearchParam,
            setSearchParams,
            whiteLabel,
            setWhiteLabel,
            hoveredPlaceIdOnGrid,
            setHoveredPlaceIdOnGrid,
            hoveredPlaceIdOnMap,
            setHoveredPlaceIdOnMap,
            isInited,
            urlPrefix,
            specificOrganizationId,
        } }, children));
}
exports.default = SearchDataLoader;
