import React from "react";
import PropTypes from "prop-types";

const MySportSessionIcon = ({ height, width, hovered }) => {
  return (
    <img
      src="/build/images/apple-touch-icon.png"
      className="map-maker"
      alt="mysportsession icon"
      width={width}
      height={height}
      style={hovered ? { filter: "brightness(70%) hue-rotate(210deg)" } : {}}
    />
  );
};

MySportSessionIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  hovered: PropTypes.bool,
};

MySportSessionIcon.defaultProps = {
  height: null,
  width: null,
  hovered: false,
};

export default MySportSessionIcon;
