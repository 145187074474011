import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import { FavoriteActions, FavoriteSelectors } from "../data/favorite";
import { CommonSelectors } from "../data/common";

class KeepInformedButtonContainer extends Component {
  render() {
    const {
      entityId,
      className,
      addFavorite,
      removeFavorite,
      favorites,
      waitingFavorites,
      userIsConnected,
    } = this.props;

    if (!userIsConnected) {
      return (
        <button
          className="btn btn-danger btn-lg"
          onClick={(e) => {
            e.preventDefault();
            document.location.href = "/register/?from=addFavorite";
          }}
        >
          Tenez-moi informé
        </button>
      );
    }

    if (waitingFavorites.has(`${className}-${entityId}`)) {
      return (
        <button className="btn btn-danger btn-lg">
          Tenez-moi informé
          <div
            className="m-l-20 spinner-border text-light"
            role="status"
            style={{ width: "27px", height: "27px" }}
          >
            <span className="sr-only">Loading...</span>
          </div>
        </button>
      );
    }

    if (favorites.has(`${className}-${entityId}`)) {
      return (
        <button
          className="btn btn-danger btn-lg"
          onClick={(e) => {
            e.preventDefault();
            removeFavorite(className, entityId);
          }}
        >
          Demande enregistrée. Nous vous tiendrons informé !
        </button>
      );
    }

    return (
      <button
        className="btn btn-danger btn-lg"
        onClick={(e) => {
          e.preventDefault();
          addFavorite(className, entityId);
        }}
      >
        Tenez-moi informé
      </button>
    );
  }
}

const mapStateToProps = (state) => ({
  favorites: FavoriteSelectors.getFavorites(state),
  waitingFavorites: FavoriteSelectors.getWaitingFavorites(state),
  userIsConnected: CommonSelectors.getUserIsConnected(state),
});

const mapDispatchToProps = (dispatch) => ({
  addFavorite: (className, entityId) =>
    dispatch(FavoriteActions.addFavorite(className, entityId)),
  removeFavorite: (className, entityId) =>
    dispatch(FavoriteActions.removeFavorite(className, entityId)),
});

KeepInformedButtonContainer.propTypes = {
  entityId: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,

  favorites: PropTypes.any.isRequired,
  waitingFavorites: PropTypes.any.isRequired,
  addFavorite: PropTypes.func.isRequired,
  removeFavorite: PropTypes.func.isRequired,
  userIsConnected: PropTypes.bool.isRequired,
};

KeepInformedButtonContainer.defaultProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KeepInformedButtonContainer);
