import React, { Component } from "react";
import connect from "react-redux/es/connect/connect";
import PropTypes from "prop-types";
import ReactDOMServer from "react-dom/server";
import { Map as LeafletMap, Marker, TileLayer, Tooltip } from "react-leaflet";
import { getPlaces } from "../../shared/data/basicMap/BasicMapSelectors";

class RootContainer extends Component {
  render() {
    const { places } = this.props;
    const bounds = [];
    places.forEach((p) => {
      if (p.lat && p.lon) {
        bounds.push([p.lat, p.lon]);
      }
    });

    if (bounds.length === 0) {
      return null;
    }

    const mainIcon = L.divIcon({
      html: ReactDOMServer.renderToString(
        <i className="map-maker fas fa-map-marker-alt color-red" />
      ),
    });

    const icon = L.divIcon({
      html: ReactDOMServer.renderToString(
        <i className="map-maker fas fa-map-marker-alt color-blue" />
      ),
    });

    return (
      <div className="basicMap">
        <LeafletMap
          bounds={bounds.length > 1 ? bounds : null}
          center={bounds.length === 1 ? bounds[0] : null}
          zoom={bounds.length === 1 ? 15 : null}
          dragging={window.innerWidth > 768}
          scrollWheelZoom={window.innerWidth > 768}
        >
          <TileLayer
            url="https://{s}-tiles.locationiq.com/v2/streets/r/{z}/{x}/{y}.vector?key=pk.ea19caf7376ed9efc60d6622f39faead"
            attribution={`© <a href="https://locationiq.com" target="_blank">LocationIQ Maps</a>, <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a></div>`}
          />
          {places.map((p) => {
            if (p.lat && p.lon) {
              return (
                <Marker
                  key={`${p.lat}-${p.lon}`}
                  position={[p.lat, p.lon]}
                  icon={p.isMainPlace ? mainIcon : icon}
                >
                  <Tooltip>
                    <b>{p.name}</b>
                    <br />
                    {p.address}
                  </Tooltip>
                </Marker>
              );
            }
            return null;
          })}
        </LeafletMap>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  places: getPlaces(state),
});

const mapDispatchToProps = (dispatch) => ({});

RootContainer.propTypes = {
  places: PropTypes.array,
};

RootContainer.defaultProps = {
  places: [],
};

export default connect(mapStateToProps, mapDispatchToProps)(RootContainer);
