export const BOOKING_MODAL_OPEN = "BOOKING_BOOKING_MODAL_OPEN";
export const BOOKING_MODAL_CLOSE = "BOOKING_BOOKING_MODAL_CLOSE";
export const BOOKING_FORM_SUBMIT = "BOOKING_BOOKING_FORM_SUBMIT";
export const BOOKING_FORM_SUCCESS = "BOOKING_BOOKING_FORM_SUCCESS";

export const BOOKING_FORM_SET_NUMBER_OF_UNITS =
  "BOOKING_FORM_SET_NUMBER_OF_UNITS";
export const BOOKING_FORM_SET_NUMBER_OF_ATHLETES =
  "BOOKING_FORM_SET_NUMBER_OF_ATHLETES";
export const BOOKING_FORM_SET_NOTIFY_ATHLETES =
  "BOOKING_FORM_SET_NOTIFY_ATHLETES";

export const BOOKING_WAITING_LIST_MODAL_OPEN =
  "BOOKING_WAITING_LIST_BOOKING_MODAL_OPEN";
export const BOOKING_WAITING_LIST_MODAL_CLOSE =
  "BOOKING_WAITING_LIST_BOOKING_MODAL_CLOSE";

export const BOOKING_TO_BOOK_INFORMATION_MODAL_OPEN =
  "BOOKING_TO_BOOK_INFORMATION_MODAL_OPEN";
export const BOOKING_TO_BOOK_INFORMATION_MODAL_CLOSE =
  "BOOKING_TO_BOOK_INFORMATION_MODAL_CLOSE";
