export const initialState = {
  platform: null,
  partner: null,
  urlPrefix: null,
  user: {
    isConnected: false,
    username: null,
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;
