import * as BookingActionsTypes from "./BookingActionsTypes";

export const initialState = {
  session: null,
  modalIsOpen: false,
  waitingListModalIsOpen: false,
  toBookInformationModalIsOpen: false,
  isSuccess: false,
  numberOfUnits: null,
  numberOfAthletes: null,
  notifyAthletes: null,
};

const openBookingModal = (state, session) => ({
  ...state,
  session,
  modalIsOpen: true,
  numberOfUnits: null,
});

const closeBookingModal = (state) => ({
  ...state,
  session: null,
  modalIsOpen: false,
  isSuccess: false,
  numberOfUnits: null,
});

const openWaitingListModal = (state, session) => ({
  ...state,
  session,
  waitingListModalIsOpen: true,
});

const closeWaitingModal = (state) => ({
  ...state,
  session: null,
  waitingListModalIsOpen: false,
});

const openToBookInformationModal = (state, session) => ({
  ...state,
  session,
  toBookInformationModalIsOpen: true,
});

const closeToBookInformationModal = (state) => ({
  ...state,
  session: null,
  toBookInformationModalIsOpen: false,
});

const onFormSuccess = (state) => ({
  ...state,
  isSuccess: true,
});

const setNumberOfUnits = (state, numberOfUnits) => ({
  ...state,
  numberOfUnits,
});

const setNumberOfAthletes = (state, numberOfAthletes) => ({
  ...state,
  numberOfAthletes,
});

const setNotifyAthletes = (state, notifyAthletes) => ({
  ...state,
  notifyAthletes,
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case BookingActionsTypes.BOOKING_MODAL_OPEN:
      return openBookingModal(state, action.session);

    case BookingActionsTypes.BOOKING_MODAL_CLOSE:
      return closeBookingModal(state);

    case BookingActionsTypes.BOOKING_WAITING_LIST_MODAL_OPEN:
      return openWaitingListModal(state, action.session);

    case BookingActionsTypes.BOOKING_WAITING_LIST_MODAL_CLOSE:
      return closeWaitingModal(state);

    case BookingActionsTypes.BOOKING_TO_BOOK_INFORMATION_MODAL_OPEN:
      return openToBookInformationModal(state, action.session);

    case BookingActionsTypes.BOOKING_TO_BOOK_INFORMATION_MODAL_CLOSE:
      return closeToBookInformationModal(state);

    case BookingActionsTypes.BOOKING_FORM_SUCCESS:
      return onFormSuccess(state);

    case BookingActionsTypes.BOOKING_FORM_SET_NUMBER_OF_UNITS:
      return setNumberOfUnits(state, action.numberOfUnits);

    case BookingActionsTypes.BOOKING_FORM_SET_NUMBER_OF_ATHLETES:
      return setNumberOfAthletes(state, action.numberOfAthletes);

    case BookingActionsTypes.BOOKING_FORM_SET_NOTIFY_ATHLETES:
      return setNotifyAthletes(state, action.notifyAthletes);

    default:
      return state;
  }
};

export default reducer;
