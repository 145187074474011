import { put, takeLatest, delay, select, call } from "redux-saga/effects";
import {
  AutocompleteActionsTypes,
  AutocompleteActions,
  AutocompleteSelectors,
} from "./index";
import axios from "axios";

export function* autocompleteFetchSaga({ search }) {
  if (!search) {
    return;
  }

  yield delay(500);

  const data = yield axios
    .get(`/api/location/autocomplete/${search}`)
    .then((response) => response.data);

  if (data) {
    if (data.error) {
      yield put(AutocompleteActions.autocompletesError());
    } else {
      yield put(AutocompleteActions.receivedAutocompletes(data));
    }
  }
}

export function* autocompleteSelectSaga({ place }) {
  yield put(AutocompleteActions.placeSelected());
  yield put(AutocompleteActions.addLastSelectedPlaces(place));
}

const getUserLocation = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (location) => resolve(location),
      (error) => reject(error)
    );
  });

export function* fetchLocationSaga() {
  // const locationIsAlreadyFetch = yield select(AutocompleteSelectors.locationIsAlreadyFetch)
  // if (!locationIsAlreadyFetch) {
  try {
    const location = yield call(getUserLocation);
    if (
      location &&
      location.coords &&
      location.coords.latitude &&
      location.coords.longitude
    ) {
      yield put(AutocompleteActions.locationReceived(location));
      yield put(
        AutocompleteActions.editCoordinates({
          lat: location.coords.latitude,
          lng: location.coords.longitude,
        })
      );
    } else {
      yield put(AutocompleteActions.locationError(location || true));
    }
  } catch (e) {
    yield put(AutocompleteActions.locationError(e));
  }
  // }
}

export function* locationErrorSaga() {
  // Set marker to "Place de la Comédie, Montpellier"
  yield put(
    AutocompleteActions.editCoordinates({
      lat: 43.60869245,
      lng: 3.8799232746547,
    })
  );
}

export function* reverseGeocodeFetchSaga() {
  const coordinates = yield select(AutocompleteSelectors.getLocation);
  const shouldReverseGeocode = yield select(
    AutocompleteSelectors.shouldReverseGeocode
  );

  if (
    shouldReverseGeocode &&
    coordinates &&
    coordinates.lat &&
    coordinates.lng
  ) {
    const data = yield axios
      .get("/api/location/reverseGeocode", {
        params: { ...coordinates },
      })
      .then((response) => response.data);

    if (data && data.address) {
      yield put(
        AutocompleteActions.reverseGeocodingReceived(
          data,
          coordinates.lat,
          coordinates.lng
        )
      );
    } else {
      yield put(AutocompleteActions.reverseGeocodingError());
    }
  }
}

export function* reverseGeocodeReceivedSaga({ data, latitude, longitude }) {
  yield put(
    AutocompleteActions.addLastSelectedPlaces({
      name: data.address,
      lat: latitude,
      lng: longitude,
    })
  );
}

export function* locationEditSaga({ shouldReverseGeocoding }) {
  if (shouldReverseGeocoding) {
    yield put(AutocompleteActions.fetchReverseGeocoding());
  }
}

export function* lastSelectedPlacesAddSaga() {
  const lastSelectedPlaces = yield select(
    AutocompleteSelectors.getLastSelectedPlaces
  );
  window.localStorage.setItem(
    "lastSelectedPlaces",
    JSON.stringify(lastSelectedPlaces)
  );
}

export function* lastSelectedPlacesRetrieveSaga() {
  var lastSelectedPlaces = window.localStorage.getItem("lastSelectedPlaces");
  if (lastSelectedPlaces) {
    lastSelectedPlaces = JSON.parse(lastSelectedPlaces);
    yield put(
      AutocompleteActions.lastSelectedPlacesRetrieved(lastSelectedPlaces)
    );
    if (lastSelectedPlaces.length > 0) {
      yield put(AutocompleteActions.selectPlace(lastSelectedPlaces[0]));
    }
  }
}

export function* lastSelectedPlacesAutofillSaga() {
  const lastSelectedPlaces = yield select(
    AutocompleteSelectors.getLastSelectedPlaces
  );

  if (lastSelectedPlaces && lastSelectedPlaces.length > 0) {
    yield put(AutocompleteActions.selectPlace(lastSelectedPlaces[0]));
  }
}

export const autocompleteSagas = [
  takeLatest(
    AutocompleteActionsTypes.AUTOCOMPLETE_FETCH,
    autocompleteFetchSaga
  ),
  takeLatest(
    AutocompleteActionsTypes.AUTOCOMPLETE_SELECT,
    autocompleteSelectSaga
  ),
  takeLatest(AutocompleteActionsTypes.LOCATION_FETCH, fetchLocationSaga),
  takeLatest(AutocompleteActionsTypes.LOCATION_ERROR, locationErrorSaga),
  takeLatest(
    AutocompleteActionsTypes.REVERSE_GEOCODE_FETCH,
    reverseGeocodeFetchSaga
  ),
  takeLatest(
    AutocompleteActionsTypes.REVERSE_GEOCODE_RECEIVED,
    reverseGeocodeReceivedSaga
  ),
  takeLatest(AutocompleteActionsTypes.LOCATION_EDIT, locationEditSaga),
  takeLatest(
    AutocompleteActionsTypes.LAST_SELECTED_PLACES_ADD,
    lastSelectedPlacesAddSaga
  ),
  takeLatest(
    AutocompleteActionsTypes.LAST_SELECTED_PLACES_RETRIEVE,
    lastSelectedPlacesRetrieveSaga
  ),
  takeLatest(
    AutocompleteActionsTypes.LAST_SELECTED_PLACES_AUTOFILL,
    lastSelectedPlacesAutofillSaga
  ),
];
