/* global fetch */
import { apiHelper } from "../helpers";

const postRegistrationForm = (data) => {
  const { recaptcha, ...fosUserRegistrationForm } = data;

  const requestOptions = {
    method: "POST",
    headers: apiHelper.defaultHeaders(),
    body: JSON.stringify({
      fos_user_registration_form: fosUserRegistrationForm,
      "g-recaptcha-response": recaptcha,
    }),
  };

  return fetch("/register/?api=1", requestOptions).then(
    apiHelper.handleFormResponse
  );
};

const userApi = {
  postRegistrationForm,
};

export default userApi;
